import axios from 'axios';
import qs from 'qs';
import shortid from 'shortid';

class ModelManager {
    #instance;
    #Vue;
    constructor(Vue) {
        this.#Vue = Vue;
        this.#instance = new Vue({
            data: () => ({
                host: '',
                loaded: false,
                wasMissingHost: false,
            })
        })
        this.loadConfig();
    }

    get instance() {
        return this.#instance;
    }

    async loadConfig() {
        const curi = `${process.env.BASE_URL}config.json?${qs.stringify({
            _: shortid.generate(),
        })}`;
        try {
            const {data} = await axios.get(curi);
            const {host} = data;
            if (host) {
                this.#instance.host = host;
            }
        }
        catch (error) {
            // do nothing
        }
        if (!this.#instance.host) {
            this.#instance.host = this.#Vue.prototype.$storage.get('host');
            this.#instance.wasMissingHost = true;
        }
        this.#instance.loaded = true;
        this.#instance.$emit('loaded');
    }

    static install (Vue, config) {
        const instance = new this(Vue, config);
        Vue.prototype.$config = instance.instance;
    }
}

export default ModelManager;