<template>
  <v-app>
    <v-app-bar app color="toolbar" dense class="remove-padding" clipped-left>
      <v-img
        alt="Feriority Logo"
        class="shrink mx-2"
        contain
        src="./assets/images/android-chrome-192x192-inverted.png"
        transition="scale-transition"
        width="30"
      />
      <v-toolbar-items class="mx-3">
        <v-btn :text="$vuetify.breakpoint.smAndUp" :icon="$vuetify.breakpoint.xsOnly" v-if="'Home' !== $route.name" :to="{name: 'Home'}" exact>
          <v-icon v-if="$vuetify.breakpoint.xsOnly">mdi-home-currency-usd</v-icon>
          <span v-else>Scoreboard</span>
        </v-btn>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <!-- <v-switch hide-details color="amber" dark v-model="$vuetify.theme.dark" prepend-icon="mdi-weather-sunny" append-icon="mdi-weather-night" class="mx-2"></v-switch> -->
      <v-toolbar-items v-if="!$auth.authenticated">
        <v-btn text @click="connectionButton.cb" x-small>
          <v-icon :color="connectionButton.color" v-text="connectionButton.icon"></v-icon>
        </v-btn>
        <v-btn text @click="logout" x-small v-if="$auth.authenticated" :loading="loading.logout">
          <v-icon color="error">mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
      <v-toolbar-items v-else>
        <v-btn icon v-if="$auth.can('dialer:summary')" :to="{name: 'dialer/summary'}">
          <v-icon>mdi-face-agent</v-icon>
        </v-btn>
        <v-btn icon v-if="$auth.can('campaigns:live')" :to="{name: 'campaigns/live'}">
          <v-icon>mdi-bullhorn</v-icon>
        </v-btn>
        <v-btn icon @click="show.chatbot = true">
          <v-icon>mdi-robot</v-icon>
        </v-btn>
        <v-btn icon v-if="$auth.can('admin:list')" :to="{name: 'admin/list'}">
          <v-icon>mdi-cogs</v-icon>
        </v-btn>
        <v-btn icon v-if="$auth.can('reports:list')" :to="{name: 'reports/list'}">
          <v-icon>mdi-file-table-box-multiple</v-icon>
        </v-btn>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title v-text="$auth.info.username" class="error--text text-end"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-switch hide-details color="amber" v-model="$vuetify.theme.dark" prepend-icon="mdi-weather-sunny" append-icon="mdi-weather-night" class="mx-2 my-0"></v-switch>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item v-if="$auth.can('me:personal')" @click="show.my.account = true;">
              <v-list-item-avatar>
                <v-icon>mdi-badge-account-horizontal</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>My Account</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="$auth.can('me:security')" @click="show.my.security = true;">
              <v-list-item-avatar>
                <v-icon>mdi-shield-account</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Security</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="show.my.notifications = true;">
              <v-list-item-avatar>
                <v-icon>mdi-message-cog</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Notifications</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="$auth.can('me:chatbots')" :to="{name: 'my/chatbots'}">
              <v-list-item-avatar>
                <v-icon>mdi-android-debug-bridge</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Chatbots</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="hasMenuDivider"></v-divider>
            <v-list-item @click="connectionButton.cb">
              <v-list-item-avatar>
                <v-icon :color="connectionButton.color" v-text="connectionButton.icon"></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-text="connectionButton.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="sw.registration" @click="refreshVersion">
              <v-list-item-avatar>
                <v-icon>mdi-source-branch-refresh</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Check for Updated Version</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="logout">
              <v-list-item-avatar>
                <v-icon color="error">mdi-close</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Log Out</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>

    <hostForm v-if="initialized"></hostForm>
    <authForm v-if="initialized && 'string' == typeof $config.host && $config.host.trim().length > 0"></authForm>
    <totpForm v-if="initialized && $auth.authenticated"></totpForm>
    <mfaForm v-if="initialized && $auth.authenticated && $auth.meta.totp"></mfaForm>
    <myAccountForm v-if="show.my.account" @close="show.my.account = false"></myAccountForm>
    <mySecurityForm v-if="show.my.security" @close="show.my.security = false"></mySecurityForm>
    <myNotificationsForm v-if="show.my.notifications" @close="show.my.notifications = false" v-model="notificationSettings"></myNotificationsForm>
    <chatbotForm v-model="show.chatbot" @close="show.chatbot = false"></chatbotForm>

    <v-overlay v-if="!initialized">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-snackbar v-if="$io.initialized" :value="!$io.connected" app bottom :timeout="-1" color="error darken-3">
      The Server is not connected. Some functionality may be limited.
    </v-snackbar>

    <v-main>
      <router-view v-if="initialized && $io.initialized && $currency.base" :key="$route.path"></router-view>
    </v-main>
    
    <v-footer app padless color="toolbar">
      <v-toolbar dense color="toolbar" height="25">
        <v-toolbar-title class="caption">
          <code v-text="$config.host"></code>
        </v-toolbar-title>
        <v-toolbar-items v-if="$config.wasMissingHost">
          <v-btn icon color="error" @click="disconhost" dark>
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-tooltip top v-if="$config.loaded">
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mx-2" small v-text="connection.icon" :color="connection.color" v-bind="attrs" v-on="on"></v-icon>
            </template>
            <span v-text="connection.text"></span>
          </v-tooltip>
          <v-tooltip top v-if="$config.loaded">
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mx-2" small v-text="security.icon" :color="security.color" v-bind="attrs" v-on="on"></v-icon>
            </template>
            <span v-text="security.text"></span>
          </v-tooltip>
        </v-toolbar-items>
      </v-toolbar>
    </v-footer>
    <v-snackbar app top center :value="sw.updateExists" :timeout="0" color="indigo" shaped fixed>
      An Application Update is Available
      <template v-slot:action="{ attrs }">
        <v-btn text @click="refreshApp" v-bind="attrs">
          Update
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import hostForm from './components/login/host';
import authForm from './components/login/authentication';
import totpForm from './components/login/totp';
import mfaForm from './components/login/mfa';

import myAccountForm from './components/me/account';
import mySecurityForm from './components/me/security';
import myNotificationsForm from './components/me/notifications';

import chatbotForm from './components/general/chatbot';

import { getName } from 'country-list';

import merge from 'lodash.merge';
import shortid from 'shortid';

export default {
  name: 'App',

  components: {
    hostForm,
    authForm,
    totpForm,
    mfaForm,
    myAccountForm,
    mySecurityForm,
    myNotificationsForm,
    chatbotForm,
  },

  data: () => ({
    initialized: false,
    loading: {
      logout: false,
    },
    sw: {
      registration: null,
      updateExists: false,
      refreshing: false,
    },
    intervals: {
      sw: null,
    },
    show: {
      my: {
        account: false,
        security: false,
        notifications: false,
      },
      chatbot: false,
    },
    notificationSettings: {
      lead: {
        visualType: 'none',
        visualShow: 'never',
        audibleTone: 'none',
        audiblePlay: 'never',
      },
      ftd: {
        visualType: 'none',
        visualShow: 'never',
        audibleTone: 'none',
        audiblePlay: 'never',
      },
      cold: {
        visualType: 'none',
        visualShow: 'never',
        audibleTone: 'none',
        audiblePlay: 'never',
      },
    }
  }),

  computed: {
    connection() {
      return {
        icon: (this.$io.connected) ? 'mdi-check-network' : 'mdi-close-network',
        color: (this.$io.connected) ? 'success' : 'error',
        text: (this.$io.connected) ? 'Connected to Server' : 'Not connected to Server',
      }
    },
    connectionButton() {
      return {
        icon: (!this.$io.connected) ? 'mdi-plus-network' : 'mdi-close-network',
        color: (!this.$io.connected) ? 'primary' : 'error',
        cb: (!this.$io.connected) ? this.connect : this.disconnect,
        text: (!this.$io.connected) ? 'Connect' : 'Disconnect',
      }
    },
    security() {
      return {
        icon: (this.$io.secure) ? 'mdi-shield-check' : 'mdi-shield-alert',
        color: (this.$io.secure) ? 'success' : 'error',
        text: (this.$io.secure) ? 'Using Secure Connection' : 'Not using Secure Connection',
      }
    },
    hasMenuDivider() {
      return (this.$auth.can('me:personal') || this.$auth.can('me:security'))
    }
  },

  methods: {
    connect() {
      const host = this.$config.host;
      const jwt = this.$auth.jwt;
      if (host && jwt) {
        try {
          this.$io.connect(host, jwt);
        }
        catch (error) {
          // do nothing
        }
      }
    },
    disconnect() {
      this.$io.disconnect();
      this.$storage.remove('jwt');
    },
    disconhost() {
      this.disconnect();
      this.$storage.remove('host');
      this.$config.host = null;
    },
    async logout() {
      const host = this.$config.host;
      this.loading.logout = true;
      this.disconnect();
      await this.$auth.logout();
      this.loading.logout = false;
      // Fix because somewhere this gets removed / deleted
      this.$config.host = host;
      this.$storage.set('host', host);
    },
    updateAvailable(event) {
      this.sw.registration = event.detail
      this.sw.updateExists = true
    },
    captureServiceWorker(event) {
      this.sw.registration = event.detail;
      this.intervals.sw = setInterval(() => {
        this.sw.registration.update();
      }, 300000)
    },
    refreshApp() {
      this.sw.updateExists = false
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.sw.registration || !this.sw.registration.waiting) return
      // Send message to SW to skip the waiting and activate the new SW
      this.sw.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    },
    handleEnterRoute(to, from, next) {
      if (!this.$auth.authenticated && 'function' == typeof next) {
        return next();
      }
      if (to.meta && to.meta.permissions) {
        let has = true;
        for (let i = 0; i < to.meta.permissions.length; i++) {
          const perm = to.meta.permissions[i];
          if (!this.$auth.can(perm)) {
            has = false;
          }
        }
        if (!has) {
          this.$PNotify.error({
            title: 'Access Forbidden',
            text: 'You are not allowed to access the requested link'
          })
          if ('function' == typeof next) {
            return next(false)
          }
          else {
            this.$router.push({name: 'Home'});
          }
        }
        if ('function' == typeof next) {
          return next();
        }
      }
      else {
        if ('function' == typeof next) {
          return next();
        }
      }
    },
    async handleConversion(payload) {
      console.debug(`got ${payload.queue} conversion ${payload.id}`);
      const {visualType, visualShow, audibleTone, audiblePlay} = this.notificationSettings[payload.queue];
      if ('never' !== visualShow) {
        let show = true;
        if ('focused' == visualShow) {
          show = (!document.hidden);
        }
        else if ('leader' == visualShow) {
          show = await this.$CTCM.getIsLeader(true);
        }
        if (show) {
          switch (visualType) {
            case 'PNotify':
              this.$PNotify.info({
                title: this._getConversionTitle(payload),
                text: this._getConversionShortText(payload),
                icon: false,
              })
              break;
            case 'Swal':
              this.$Swal.fire({
                title: this._getConversionTitle(payload),
                html: this._getConversionLongText(payload),
                icon: 'info',
                timer: 15000,
              })
              break;
          }
        }
      }
      if ('never' !== audiblePlay) {
        let show = true;
        if ('focused' == audiblePlay) {
          show = (!document.hidden);
        }
        else if ('leader' == audiblePlay) {
          show = await this.$CTCM.getIsLeader(true);
        }
        if (show) {
          this.$sounds.play(audibleTone);
        }
      }
    },
    _getConversionTitle(payload) {
      switch (payload.queue) {
        case 'cold':
          return `${payload.department.name} FTD`;

        case 'lead':
          return `${payload.publisher.name} Lead`;

        case 'ftd':
          return `${payload.publisher.name} FTD`;
      }
    },
    _getConversionShortText(payload) {
      const parts = [];
      if (payload.crm) {
        parts.push(`CRM: ${payload.crm.name}`);
      }
      if (payload.department) {
        parts.push(`Department: ${payload.department.name}`);
      }
      if (payload.publisher) {
        parts.push(`Publisher: ${payload.publisher.name}`);
      }
      if (payload.country) {
        parts.push(`Country: ${payload.country}`);
      }
      return parts.join(', ');
    },
    _getConversionLongText(payload) {
      let html = '';
      switch (payload.queue) {
        case 'cold':
          html += `<p>New Cold FTD:</p>`;
          break;

        case 'lead':
          html += `<p>New Lead:</p>`;
          break;

        case 'ftd':
          html += `<p>New Warm FTD:</p>`;
          break;
      }
      html += '<ul>';
      if (payload.crm) {
        html += `<li><strong>CRM:</strong> ${payload.crm.name}</li>`;
      }
      if (payload.department) {
        html += `<li><strong>Department:</strong> ${payload.department.name}</li>`;
      }
      if (payload.publisher) {
        html += `<li><strong>Publisher:</strong> ${payload.publisher.name}</li>`;
      }
      if (payload.campaign) {
        html += `<li><strong>Source Campaign:</strong> ${payload.campaign.name}</li>`;
      }
      if (payload.batch) {
        html += `<li><strong>Source Batch:</strong> ${payload.batch.name}</li>`;
      }
      if (payload.country) {
        html += `<li><strong>Country:</strong> ${getName(payload.country)}</li>`;
      }
      html += '</ul>';
      return html;
    },
    refreshVersion() {
      if (this.sw.registration) {
        if ('function' == typeof this.sw.registration.update) {
          this.sw.registration.update().then(() => {
            window.location.reload();
          })
        }
        else {
          this.refreshApp();
          window.location.reload();
        }
      }
    }
  },

  mounted() {
    this.$auth.io = this.$io;
    this.$router.beforeEach((to, from, next) => {
      this.handleEnterRoute(to, from, next);
    })
    this.handleEnterRoute(this.$route);
    this.$watch('$auth.authenticated', () => {
      this.handleEnterRoute(this.$route);
    })
    this.$io.$on('connect_error', (error) => {
      this.$Swal.fire({
        icon: 'error',
        title: 'Connection Error',
        text: error.toString(),
      })
    })
    this.$io.$on('connected', async () => {
      if (this.sw.registration) {
        this.sw.registration.update();
      }
    });
    this.$io.$on('conversion', this.handleConversion);
    this.connect();
    this.$config.$on('loaded', () => {
      console.debug('Site Configuration Loaded', this.$config.host);
      this.connect();
    })
    setTimeout(() => {
      this.initialized = true;
    }, 500);
    this.$watch('$auth.ready', () => {
      if (this.$auth.ready) {
        this.connect();
      }
      else {
        this.disconnect();
      }
    })
    const dark = this.$storage.get('theme_dark');
    if (true === dark || false === dark) {
      this.$vuetify.theme.dark = dark;
    }
    this.$watch('$vuetify.theme.dark', () => {
      this.$storage.set('theme_dark', this.$vuetify.theme.dark)
    });
    document.addEventListener('swRegistered', this.captureServiceWorker, { once: true });
    document.addEventListener('swUpdated', this.updateAvailable, { once: true });
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      // We'll also need to add 'refreshing' to our data originally set to false.
      if (this.sw.refreshing) return
      this.sw.refreshing = true
      // Here the actual reload of the page occurs
      window.location.reload()
    })
    this.$watch('sw.updateExists', () => {
      if (this.sw.updateExists && 'Home' == this.$route.name) {
        this.$Swal.fire({
          icon: 'info',
          title: 'Updating Application',
          text: 'The application is being updated and will reload automatically once the update is complete.',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: true,
          showConfirmButton: false,
        })
        this.refreshApp();
      }
    })
    if (this.sw.updateExists && 'Home' == this.$route.name) {
      this.$Swal.fire({
        icon: 'info',
        title: 'Updating Application',
        text: 'The application is being updated and will reload automatically once the update is complete.',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: true,
        showConfirmButton: false,
      })
      this.refreshApp();
    }
    if (this.$route && this.$route.query && this.$route.query.tabId) {
      const key = ['notificationSettingsFor', this.$route.query.tabId].join('');
      const notificationSettings = this.$storage.get(key);
      if (notificationSettings) {
        this.notificationSettings = notificationSettings;
      }
    }
  },

  beforeDestroy() {
    for (let i in this.intervals) {
      clearInterval(this.intervals[i]);
    }
  },

  watch: {
    notificationSettings: {
          deep: true,
          handler(nv) {
              let tabId;
              let needSet = false;
              if (this.$route && this.$route.query && this.$route.query.tabId) {
                  tabId = this.$route.query.tabId;
              }
              else {
                  tabId = shortid.generate();
                  needSet = true;
              }
              const obj = merge({}, nv);
              this.$storage.set(['notificationSettingsFor', tabId].join(''), obj);
              if (needSet) {
                const p = {
                  name: (this.$route && this.$route.name) ? this.$route.name : 'Home',
                  query: (this.$route && this.$route.query) ? merge({}, this.$route.query, {tabId}) : {tabId},
                }
                this.$router.push(p, () => {}, () => {});
              }
          }
      }
  }
};
</script>
