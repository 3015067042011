<template>
    <v-dialog :value="true" max-width="800" persistent overlay-color="background">
        <validation-observer slim ref="observer" v-slot="{}">
            <v-card color="panel">
                <v-toolbar color="toolbar" dense flat class="remove-end-padding">
                    <v-toolbar-title>In-App Notifications</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn text @click="$emit('close')">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-divider color="white"></v-divider>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>Event</th>
                                <th>Visual Alert Type</th>
                                <th>Show When</th>
                                <th>Audible Alert</th>
                                <th>Play When</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Warm Registration</td>
                                <td>
                                    <validation-provider v-slot="{ errors }" name="Visual Alert Type" rules="required">
                                        <v-select dense v-model="_value.lead.visualType" :items="visualAlertOptions" :error-messages="errors" :hide-details="errors.length == 0" placeholder="Visual Alert Type" required></v-select>
                                    </validation-provider>
                                </td>
                                <td>
                                    <validation-provider v-slot="{ errors }" name="Show When" rules="required">
                                        <v-select dense v-model="_value.lead.visualShow" :items="showPlayWhenOptions" :error-messages="errors" :hide-details="errors.length == 0" placeholder="Show When" required></v-select>
                                    </validation-provider>
                                </td>
                                <td>
                                    <validation-provider v-slot="{ errors }" name="Audible Alert Type" rules="required">
                                        <v-select dense v-model="_value.lead.audibleTone" :items="audibleAlertOptions" :error-messages="errors" :hide-details="errors.length == 0" placeholder="Audible Alert Type" required append-icon="mdi-play-circle-outline" @click:append="playPreviewFor('lead')"></v-select>
                                    </validation-provider>
                                </td>
                                <td>
                                    <validation-provider v-slot="{ errors }" name="Play When" rules="required">
                                        <v-select dense v-model="_value.lead.audiblePlay" :items="showPlayWhenOptions" :error-messages="errors" :hide-details="errors.length == 0" placeholder="Play When" required></v-select>
                                    </validation-provider>
                                </td>
                            </tr>
                            <tr>
                                <td>Warm FTD</td>
                                <td>
                                    <validation-provider v-slot="{ errors }" name="Visual Alert Type" rules="required">
                                        <v-select dense v-model="_value.ftd.visualType" :items="visualAlertOptions" :error-messages="errors" :hide-details="errors.length == 0" placeholder="Visual Alert Type" required></v-select>
                                    </validation-provider>
                                </td>
                                <td>
                                    <validation-provider v-slot="{ errors }" name="Show When" rules="required">
                                        <v-select dense v-model="_value.ftd.visualShow" :items="showPlayWhenOptions" :error-messages="errors" :hide-details="errors.length == 0" placeholder="Show When" required></v-select>
                                    </validation-provider>
                                </td>
                                <td>
                                    <validation-provider v-slot="{ errors }" name="Audible Alert Type" rules="required">
                                        <v-select dense v-model="_value.ftd.audibleTone" :items="audibleAlertOptions" :error-messages="errors" :hide-details="errors.length == 0" placeholder="Audible Alert Type" required append-icon="mdi-play-circle-outline" @click:append="playPreviewFor('ftd')"></v-select>
                                    </validation-provider>
                                </td>
                                <td>
                                    <validation-provider v-slot="{ errors }" name="Play When" rules="required">
                                        <v-select dense v-model="_value.ftd.audiblePlay" :items="showPlayWhenOptions" :error-messages="errors" :hide-details="errors.length == 0" placeholder="Play When" required></v-select>
                                    </validation-provider>
                                </td>
                            </tr>
                            <tr>
                                <td>Cold FTD</td>
                                <td>
                                    <validation-provider v-slot="{ errors }" name="Visual Alert Type" rules="required">
                                        <v-select dense v-model="_value.cold.visualType" :items="visualAlertOptions" :error-messages="errors" :hide-details="errors.length == 0" placeholder="Visual Alert Type" required></v-select>
                                    </validation-provider>
                                </td>
                                <td>
                                    <validation-provider v-slot="{ errors }" name="Show When" rules="required">
                                        <v-select dense v-model="_value.cold.visualShow" :items="showPlayWhenOptions" :error-messages="errors" :hide-details="errors.length == 0" placeholder="Show When" required></v-select>
                                    </validation-provider>
                                </td>
                                <td>
                                    <validation-provider v-slot="{ errors }" name="Audible Alert Type" rules="required">
                                        <v-select dense v-model="_value.cold.audibleTone" :items="audibleAlertOptions" :error-messages="errors" :hide-details="errors.length == 0" placeholder="Audible Alert Type" required append-icon="mdi-play-circle-outline" @click:append="playPreviewFor('cold')"></v-select>
                                    </validation-provider>
                                </td>
                                <td>
                                    <validation-provider v-slot="{ errors }" name="Play When" rules="required">
                                        <v-select dense v-model="_value.cold.audiblePlay" :items="showPlayWhenOptions" :error-messages="errors" :hide-details="errors.length == 0" placeholder="Play When" required></v-select>
                                    </validation-provider>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card>
        </validation-observer>
    </v-dialog>
</template>

<script>
export default {
  props: {
      value: {
          type: Object,
          required: true,
      }
  },
  computed: {
    _value: {
        set(nv) {
            this.$emit('input', nv);
        },
        get() {
            return this.value;
        }
    },
    visualAlertOptions() {
        return [
            {
                value: 'none',
                text: 'None',
            },
            {
                value: 'PNotify',
                text: 'Popup',
            },
            {
                value: 'Swal',
                text: 'Modal',
            },
        ]
    },
    audibleAlertOptions() {
        return [
            {
                value: 'none',
                text: 'None',
            }
        ].concat(this.$sounds.list);
    },
    showPlayWhenOptions() {
        return [
            {
                value: 'never',
                text: 'Never',
            },
            {
                value: 'always',
                text: 'Always',
            },
            {
                value: 'focused',
                text: 'Focused',
            },
            {
                value: 'leader',
                text: 'Lead Tab',
            },
        ]
    }
  },
  methods: {
      playPreviewFor(key) {
          const sound = this.value[key].audibleTone;
          this.$sounds.play(sound);
      }
  },
};
</script>
