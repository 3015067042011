<template>
    <div class="h-100 w-100 d-flex flex-column align-stretch" style="position: relative" ref="outerWrapper">
        <div :id="id" class="flex-grow-1 no-overflow" style="position: absolute; top: 0; left: 0; bottom: 0; right: 0;">
            <highcharts v-if="u >= 1" :options="co" :key="u"></highcharts>
        </div>
    </div>
</template>

<script>
// import Highcharts from 'highcharts'
import colors from 'vuetify/lib/util/colors'
export default {
    props: {
        bs: {
            required: true,
        },
        warm: {
            type: Boolean,
            default: true,
        },
        cold: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        viewportHeight: window.innerHeight,
        cellHeight: 0,
        u: 0,
    }),
    computed: {
        id() {
            return ['_', this._uid].join('');
        },
        co() {
            this.u;
            const ret = {
                chart: {
                    type: 'column',
                    backgroundColor: 'transparent',
                    height: this.cellHeight,
                    spacing: [10,0,3,0]
                },
                title: {
                    text: null,
                },
                colors: this.$vuetify.theme.dark ? [this.$vuetify.theme.themes.dark.info, colors.blue.darken3] : [this.$vuetify.theme.themes.light.info, colors.blue.darken3],
                credits: {
                    enabled: false,
                },
                xAxis: {
                    categories: ['CTR', 'CTL', 'CR'],
                    crosshair: true,
                    labels: {
                        margin: 0,
                        y: 14,
                    }
                },
                yAxis: {
                    min: 0,
                    // max: 100,
                    labels: {
                        // format: '{value}%',
                        enabled: false,
                    },
                    title: {
                        enabled: false,
                    },
                    gridLineColor: 'transparent',
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0,
                        dataLabels: {
                            enabled: true,
                            // format: '{y}%',
                            formatter: function() {
                                return `${parseFloat(this.point.y).toFixed(0)}%`;
                            },
                            color: (this.$vuetify.theme.dark) ? this.$vuetify.theme.themes.light.panel : this.$vuetify.theme.themes.light.toolbar,
                            backgroundColor: 'transparent',
                            borderColor: 'transparent',
                            style: {
                                textOutline: false,
                                textShadow: false,
                            },
                            // inside: true,
                            // rotation: 270,
                            allowOverlap: true,
                        }
                    }
                },
                legend: {
                    enabled: false,
                },
                tooltip: {
                    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                    pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                        '<td style="padding:0"><b>{point.y:.1f}%</b></td></tr>',
                    footerFormat: '</table>',
                    shared: true,
                    useHTML: true
                },
                series: [
                    {
                        name: 'Day',
                        data: [
                            this.getPercent(this.bs.stats.day.itl),
                            this.getPercent(this.bs.stats.day.ctl),
                            this.getPercent(this.bs.stats.day.cr),
                        ],
                    },
                    {
                        name: 'Month',
                        data: [
                            this.getPercent(this.bs.stats.month.itl),
                            this.getPercent(this.bs.stats.month.ctl),
                            this.getPercent(this.bs.stats.month.cr),
                        ],
                    },
                ]
            };
            return ret;
        }
    },
    methods: {
        onResize() {
            this.viewportHeight = window.innerHeight;
            this.cellHeight = this.getCellHeight();
        },
        getCellHeight() {
            if (this.$refs.outerWrapper) {
                return this.$refs.outerWrapper.clientHeight;
            }
            return 0;
        },
        getPercent(val) {
            if (null == val || '∞' == val) {
                return 0;
            }
            return parseFloat(val);
        }
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
            this.cellHeight = this.getCellHeight();
            this.u ++;
        })
    },
    beforeDestroy() { 
        window.removeEventListener('resize', this.onResize); 
    },
    watch: {
        viewportHeight() {
            this.u ++;
            this.cellHeight = this.getCellHeight();
        }
    }
}
</script>