const makeComponentName = (fn, append) => {
    let relativePath = fn.substr(2).replace(/\.(mp3|aac|png|svg|jpg|jpeg|gif)/g, '-$1').replace(/(-)/g, '/');
    const parts = relativePath.split('/');
    if (Array.isArray(append)) {
        for (let i = 0; i < append.length; i++) {
            const a = append[i];
            parts.push(a);
        }
    }
    if ('string' == typeof(append)) {
        parts.push(append);
    }
    return parts.map((v,i) => {
        if (i > 0) {
            return v.charAt(0).toUpperCase() + v.slice(1)
        }
        return v;
    }).join('');
}

const favicons = {};
const images = {};
const sounds = {};

// const requireFavicons = require.context('./', true, /(.*)\.(png|svg|jpg|jpeg|gif)$/);
// requireFavicons.keys().forEach(fn => {
//     if ('./index.js' !== fn) {
//         const cmp = requireFavicons(fn);
//         const name = makeComponentName(fn);
//         favicons[name] = cmp.default || cmp;
//     }
// })

// const requireImages = require.context('./', true, /(.*)\.(png|svg|jpg|jpeg|gif)$/);
// requireImages.keys().forEach(fn => {
//     if ('./index.js' !== fn) {
//         const cmp = requireImages(fn);
//         const name = makeComponentName(fn);
//         images[name] = cmp.default || cmp;
//     }
// })

const requireSounds = require.context('./', true, /(.*)\.(mp3|aac)$/);
requireSounds.keys().forEach(fn => {
    if ('./index.js' !== fn) {
        const cmp = requireSounds(fn);
        const name = makeComponentName(fn);
        sounds[name] = cmp.default || cmp;
    }
})

export {
    favicons,
    images,
    sounds
};