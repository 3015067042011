import WebFontLoader from 'webfontloader';

class FontManager {
    static install () {
        WebFontLoader.load({
            google: {
                families: ['Inconsolata'],
            },
            active: () => {
                console.debug('fonts loaded')
            },
            inactive: () => {
                console.debug('fonts not supported')
            },
        })
    }
}

export default FontManager;