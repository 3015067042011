import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify';
import fonts from './plugins/fonts';
import ctcm from './plugins/ctcm';
import storage from './plugins/storage';
import swal from './plugins/swal';
import pnotify from './plugins/pnotify';
import io from './plugins/io';
import app from './plugins/app';
import config from './plugins/config';
import auth from './plugins/auth';
import api from './plugins/api';
import currency from './plugins/currency';
import sounds from './plugins/sounds';

import { ValidationProvider, ValidationObserver, extend, setInteractionMode } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';

// import Fitty from 'vue-fitty' 

setInteractionMode('eager');
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});
extend('truthy', {
  getMessage: field => 'The ' + field + ' must be checked.',
  validate: value => !! value
});
extend('url', {
  validate(value) {
    if (value) {
      const Url = require('url-parse');
      try {
        const url = new Url(value, {});
        const tests = [
          (['http:', 'https:'].indexOf(url.protocol) > -1),
          ('string' == typeof url.hostname && url.hostname.length > 0),
        ];
        return (tests.indexOf(false) == -1);
      }
      catch (e) {
        return false;
      }
    }
    return false;
  },
  message: 'This value must be a valid URL',
})

import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts'
import highchartsMoreInit from 'highcharts/highcharts-more';
import solidGaugeInit from 'highcharts/modules/solid-gauge'
highchartsMoreInit(Highcharts);
solidGaugeInit(Highcharts);

import VueClipboard from 'vue-clipboard2'

Vue.config.productionTip = false;
Vue.use(fonts);
Vue.use(ctcm);
Vue.use(storage);
Vue.use(io);
Vue.use(swal);
Vue.use(pnotify);
Vue.use(app);
Vue.use(config);
Vue.use(auth);
Vue.use(api);
Vue.use(currency);
Vue.use(HighchartsVue);
Vue.use(sounds);
Vue.use(VueClipboard)

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

import fitted from './components/general/fitted.vue';
Vue.component('fitty', fitted);

// Vue.use(Fitty);


new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
