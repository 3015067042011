<template>
    <v-card color="panel" elevation="2" class="h-100 d-flex flex-column justify-stretch sb-board mobile my-1">
        <v-system-bar color="toolbar" class="flex-grow-0">
            <span v-text="board.text"></span>
            <v-spacer></v-spacer>
            <v-icon v-if="board.warm" color="deep-orange">mdi-fire</v-icon>
            <v-icon v-if="board.cold" color="cyan lighten-2">mdi-snowflake</v-icon>
            <v-icon @click="expand">mdi-open-in-new</v-icon>
        </v-system-bar>
        <v-divider color="white"></v-divider>
        <v-card-text class="flex-grow-1 d-flex flex-column justify-stretch">
            <v-row>
                <v-col :cols="firstRowCellWidth" class="pa-0" v-if="showActivityCell">
                    <activity
                        :ctr="bs.stats.day.ctr"
                        :ctl="bs.stats.day.ctl"
                        :cr="bs.stats.day.cr"
                        :mctr="bs.stats.month.ctr"
                        :mctl="bs.stats.month.ctl"
                        :mcr="bs.stats.month.cr"
                        :maxHeight="90"
                     v-if="board.warm"
                    ></activity>
                    <activityTarget
                        v-else
                        :targets="bs.target"
                        :maxHeight="90"
                        :external="board.external"
                        :spend="bs.stats.month.spend"
                        :cpa="bs.stats.month.cpa"
                        :ftds="bs.stats.month.ftds"
                    >
                    </activityTarget>
                </v-col>
                <v-col :cols="firstRowCellWidth" class="py-0" v-if="board.external">
                    <label class="sb-cell-label">Spend</label>
                    <span class="sb-cell-value sb-cell-value-day display-font">
                        <fitty :options="fittyOpts" :key="makeFittyKey('spend', 'day', formatCurrency(bs.stats.day.spend))">
                            <template v-slot:content>
                                <p class="mb-0" v-text="formatCurrency(bs.stats.day.spend)"></p>
                            </template>
                        </fitty>
                    </span>
                    <v-divider></v-divider>
                    <span class="sb-cell-value sb-cell-value-month display-font">
                        <fitty :options="fittyOpts" :key="makeFittyKey('spend', 'month', formatCurrency(bs.stats.month.spend))">
                            <template v-slot:content>
                                <p class="mb-0" v-text="formatCurrency(bs.stats.month.spend)"></p>
                            </template>
                        </fitty>
                        <!-- <target
                            :goal="bs.target.spend"
                            :current="bs.stats.month.spend"
                            :monthProgress="monthProgress"
                            :vh="3"
                            :fittyOpts="targetFittyOpts"
                            :formatter="formatCurrency"
                            >
                        </target> -->
                    </span>
                </v-col>
                <v-col :cols="firstRowCellWidth" class="py-0">
                    <label class="sb-cell-label">FTDs</label>
                    <span class="sb-cell-value sb-cell-value-day display-font">
                        <fitty :options="fittyOpts" :key="makeFittyKey('ftds', 'day', formatInteger(bs.stats.day.ftds))">
                            <template v-slot:content>
                                <p class="mb-0" v-text="formatInteger(bs.stats.day.ftds)"></p>
                            </template>
                        </fitty>
                    </span>
                    <v-divider></v-divider>
                    <span class="sb-cell-value sb-cell-value-month display-font">
                        <fitty :options="fittyOpts" :key="makeFittyKey('ftds', 'month', formatInteger(bs.stats.month.ftds))">
                            <template v-slot:content>
                                <p class="mb-0" v-text="formatInteger(bs.stats.month.ftds)"></p>
                            </template>
                        </fitty>
                        <!-- <target
                            :goal="bs.target.ftds"
                            :current="bs.stats.month.ftds"
                            :monthProgress="monthProgress"
                            :vh="3"
                            :fittyOpts="targetFittyOpts"
                            :formatter="formatInteger"
                            >
                        </target> -->
                    </span>
                </v-col>
                <v-col :cols="firstRowCellWidth" class="py-0" v-if="board.external">
                    <label class="sb-cell-label">CPA</label>
                    <span class="sb-cell-value sb-cell-value-day display-font">
                        <fitty :options="fittyOpts" :key="makeFittyKey('cpa', 'day', formatCurrency(bs.stats.day.cpa))">
                            <template v-slot:content>
                                <p class="mb-0" v-text="formatCurrency(bs.stats.day.cpa)"></p>
                            </template>
                        </fitty>
                    </span>
                    <v-divider></v-divider>
                    <span class="sb-cell-value sb-cell-value-month display-font">
                        <fitty :options="fittyOpts" :key="makeFittyKey('cpa', 'month', formatCurrency(bs.stats.month.cpa))">
                            <template v-slot:content>
                                <p class="mb-0" v-text="formatCurrency(bs.stats.month.cpa)"></p>
                            </template>
                        </fitty>
                        <!-- <target
                            :goal="bs.target.cpa"
                            :current="bs.stats.month.cpa"
                            :monthProgress="monthProgress"
                            :vh="3"
                            :fittyOpts="targetFittyOpts"
                            :goalIsMax="true"
                            :formatter="formatCurrency"
                            >
                        </target> -->
                    </span>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import activity from '../widgets/activity';
import activityTarget from '../widgets/activityTarget';
import numeral from 'numeral';
import md5 from 'md5';
// import target from '../widgets/targetSpan';

export default {
    components: {
        activity,
        // target,
        activityTarget,
    },
    props: {
        board: {
            type: Object,
            required: true,
        },
        stats: {
            type: Object,
            required: true,
        },
        moment: {},
        monthProgress: {},
        mobile: {
            type: Boolean,
            default: false,
        }
    },
    data: () => ({
        viewportHeight: window.innerHeight,
    }),
    computed: {
        bs() {
            if ('undefined' !== typeof this.stats[this.board.value]) {
                return this.stats[this.board.value];
            }
            else {
                return {
                    stats: {
                        day: {
                            spend: 0,
                            cpi: null,
                            cpc: null,
                            cpl: null,
                            cpa: null,
                            impressions: 0,
                            ctr: null,
                            itl: null,
                            ita: null,
                            clicks: 0,
                            ctl: null,
                            cta: null,
                            leads: 0,
                            cr: null,
                            ftds: 0,
                        },
                        month: {
                            spend: 0,
                            cpi: null,
                            cpc: null,
                            cpl: null,
                            cpa: null,
                            impressions: 0,
                            ctr: null,
                            itl: null,
                            ita: null,
                            clicks: 0,
                            ctl: null,
                            cta: null,
                            leads: 0,
                            cr: null,
                            ftds: 0,
                        }
                    },
                    target: {
                        cpa: 0,
                        ftds: 0,
                        spend: 0,
                    },
                }
            }
        },
        fittyOpts() {
            return {
                maxSize: 15,
            }
        },
        targetFittyOpts() {
            return {
                maxSize: 13,
            }
        },
        hasGoal() {
            let ret = false;
            if (this.bs && this.bs.target) {
                for (let key in this.bs.target) {
                    if (null !== this.bs.target[key] && 0 !== this.bs.target[key]) {
                        ret = true;
                    }
                }
            }
            return ret;
        },
        showActivityCell() {
            return (this.board.warm || this.hasGoal);
        },
        firstRowCellWidth() {
            let cols = 1;
            if (this.showActivityCell) {
                cols ++;
            }
            if (this.board.external) {
                cols += 2;
            }
            return Math.ceil(12 / cols);
        },
    },
    methods: {
        makeFittyKey(key, section, value) {
            return md5([key, section, value].join(':'));
        },
        formatCurrency(value) {
            if (null == value) {
                return '∞';
            }
            return this.$currency.display(value);
        },
        formatInteger(value) {
            if (null == value) {
                return '∞';
            }
            const n = numeral(value)
            return n.format('0,0');
        },
        formatPercent(value) {
            if (null == value) {
                return '∞';
            }
            const n = numeral(value)
            return `${n.format('0,0.00')}%`;
        },
        onResize() {
            this.viewportHeight = window.innerHeight
        },
        expand() {
            this.$emit('expand', this.board);
        }
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
    },
    beforeDestroy() { 
        window.removeEventListener('resize', this.onResize); 
    },
}
</script>