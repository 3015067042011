<template>
    <highcharts :options="co" :key="u"></highcharts>
</template>

<script>
import Highcharts from 'highcharts'
export default {
    props: {
        ctr: {
            type: [Object, Number],
            default: null,
        },
        ctl: {
            type: [Object, Number],
            default: null,
        },
        cr: {
            type: [Object, Number],
            default: null,
        },
        mctr: {
            type: [Object, Number],
            default: null,
        },
        mctl: {
            type: [Object, Number],
            default: null,
        },
        mcr: {
            type: [Object, Number],
            default: null,
        },
        maxHeightPercent: {
            type: Number,
            default: 0.19,
        },
        maxHeight: {
            type: Number,
        }
    },
    data: () => ({
        viewportHeight: window.innerHeight,
        u: 0,
    }),
    computed: {
        _ctr() {
            return (null == this.ctr) ? 0 : Math.round(this.ctr);
        },
        _ctl() {
            return (null == this.ctl) ? 0 : Math.round(this.ctl);
        },
        _cr() {
            return (null == this.cr) ? 0 : Math.round(this.cr);
        },
        _mctr() {
            return (null == this.mctr) ? 0 : Math.round(this.mctr);
        },
        _mctl() {
            return (null == this.mctl) ? 0 : Math.round(this.mctl);
        },
        _mcr() {
            return (null == this.mcr) ? 0 : Math.round(this.mcr);
        },
        co() {
            const ret = {
                chart: {
                    backgroundColor: 'transparent',
                    type: 'solidgauge',
                    height: '110%',
                    style: {
                        maxHeight: (this.maxHeight) ? `${this.maxHeight}px` : `${this.viewportHeight * this.maxHeightPercent}px`,
                    }
                },
                title: {
                    text: '',
                    style: {
                        color: 'silver',
                        fontSize: '1px'
                    }
                },
                colors: ['#C62828', '#689F38', '#0277BD'],
                tooltip: {
                    borderWidth: 0,
                    backgroundColor: Highcharts.color('#1d1d1f')
                                .setOpacity(0.95)
                                .get(),
                    shadow: false,
                    style: {
                        color: 'white',
                        fontSize: '10px'
                    },
                    valueSuffix: '%',
                    pointFormat: '{series.name}<br><span style="font-size:2em; color: font-weight: bold">{point.y}</span>',
                    positioner: function (labelWidth) {
                        return {
                            x: (this.chart.chartWidth - labelWidth) / 2,
                            y: (this.chart.plotHeight / 2) + 15
                        };
                    }
                },
                credits: {
                    enabled: false,
                },
                pane: {
                    startAngle: 0,
                    endAngle: 360,
                    background: [
                        { // Track for Daily CTR
                            outerRadius: '112%',
                            innerRadius: '98%',
                            backgroundColor: Highcharts.color('#C62828')
                                .setOpacity(0.3)
                                .get(),
                            borderWidth: 0
                        },
                        { // Track for Daily CTL
                            outerRadius: '97%',
                            innerRadius: '83%',
                            backgroundColor: Highcharts.color('#689F38')
                                .setOpacity(0.3)
                                .get(),
                            borderWidth: 0
                        },
                        { // Track for Daily CR
                            outerRadius: '82%',
                            innerRadius: '68%',
                            backgroundColor: Highcharts.color('#0277BD')
                                .setOpacity(0.3)
                                .get(),
                            borderWidth: 0
                        },
                        { // Track for Monthly CTR
                            outerRadius: '67%',
                            innerRadius: '53%',
                            backgroundColor: Highcharts.color('#C62828')
                                .setOpacity(0.3)
                                .get(),
                            borderWidth: 0
                        },
                        { // Track for Monthly CTL
                            outerRadius: '52%',
                            innerRadius: '38%',
                            backgroundColor: Highcharts.color('#689F38')
                                .setOpacity(0.3)
                                .get(),
                            borderWidth: 0
                        },
                        { // Track for Monthly CR
                            outerRadius: '37%',
                            innerRadius: '23%',
                            backgroundColor: Highcharts.color('#0277BD')
                                .setOpacity(0.3)
                                .get(),
                            borderWidth: 0
                        },
                    ]
                },
                yAxis: {
                    min: 0,
                    max: 100,
                    lineWidth: 0,
                    tickPositions: []
                },
                plotOptions: {
                    solidgauge: {
                        dataLabels: {
                            enabled: false
                        },
                        linecap: 'round',
                        stickyTracking: false,
                        rounded: true
                    }
                },
                series: [
                    {
                        name: 'CTR Today',
                        data: [{
                            color: '#C62828',
                            radius: '112%',
                            innerRadius: '98%',
                            y: this._ctr
                        }]
                    },
                    {
                        name: 'CTL Today',
                        data: [{
                            color: '#689F38',
                            radius: '97%',
                            innerRadius: '83%',
                            y: this._ctl
                        }]
                    },
                    {
                        name: 'CR Today',
                        data: [{
                            color: '#0277BD',
                            radius: '82%',
                            innerRadius: '68%',
                            y: this._cr
                        }]
                    },
                    {
                        name: 'CTR This Month',
                        data: [{
                            color: '#C62828',
                            radius: '67%',
                            innerRadius: '53%',
                            y: this._mctr
                        }]
                    },
                    {
                        name: 'CTL This Month',
                        data: [{
                            color: '#689F38',
                            radius: '52%',
                            innerRadius: '38%',
                            y: this._mctl
                        }]
                    },
                    {
                        name: 'CR This Month',
                        data: [{
                            color: '#0277BD',
                            radius: '37%',
                            innerRadius: '23%',
                            y: this._mcr
                        }]
                    },
                ]
            };
            return ret;
        }
    },
    methods: {
        onResize() {
            this.viewportHeight = window.innerHeight
        }
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
    },
    beforeDestroy() { 
        window.removeEventListener('resize', this.onResize); 
    },
    watch: {
        viewportHeight() {
            this.u ++;
        }
    }
}
</script>