class ModelManager {
    #instance;
    constructor(Vue) {
        this.#instance = new Vue({
            data: () => ({
                scoreboards: {}
            })
        })
    }

    get instance() {
        return this.#instance;
    }

    static install (Vue, config) {
        const instance = new this(Vue, config);
        Vue.prototype.$app = instance.instance;
    }
}

export default ModelManager;