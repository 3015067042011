class ModelManager {
    #instance;
    constructor(Vue) {
        this.#instance = new Vue({
            data: () => ({
                jwt: null,
                info: {
                    username: null,
                    email: null,
                    phone: null,
                    country: null,
                    timezone: null,
                    currency: null,
                },
                permissions: [],
                meta: {
                    totp: false,
                    mfa: false,
                },
                u: 0,
                io: null,
            }),
            computed: {
                authenticated() {
                    this.u;
                    return ([
                        ('string' == typeof this.jwt && this.jwt.trim().length > 0),
                        ('string' == typeof this.info.username && this.info.username.trim().length > 0),
                    ].indexOf(false) == -1)
                },
                ready() {
                    this.u;
                    return ([
                        this.authenticated,
                        this.meta.totp,
                        this.meta.mfa
                    ].indexOf(false) == -1)
                }
            },
            methods: {
                can(perm) {
                    if ('string' == typeof perm) {
                        return (this.permissions.indexOf(perm) > -1);
                    }
                    else if (Array.isArray(perm)) {
                        return (perm.map((p) => {
                            return this.can(p);
                        }).indexOf(false) > -1)
                    }
                    else {
                        return false;
                    }
                },
                async login(form) {
                    if (Vue.prototype.$api) {
                        try {
                            const {data} = await Vue.prototype.$api.post('/api/auth/login', form);
                            const {body} = data;
                            const {jwt, info, permissions, meta} = body;
                            this.jwt = jwt;
                            for (let key in info) {
                                this.info[key] = info[key];
                            }
                            while (this.permissions.length > 0) {
                                this.permissions.splice(0, 1);
                            }
                            for (let i = 0; i < permissions.length; i++) {
                                const perm = permissions[i];
                                this.permissions.push(perm);
                            }
                            for (let key in meta) {
                                this.meta[key] = meta[key];
                            }
                            if (Vue.prototype.$CTCM) {
                                Vue.prototype.$CTCM.emit('authentication', body);
                            }
                            if (Vue.prototype.$storage) {
                                Vue.prototype.$storage.set('auth', body);
                            }
                            return true;
                        }
                        catch (error) {
                            this.onAxiosError(error, true);
                        }
                    }
                    else {
                        return false;
                    }
                },
                async submitTotp(form) {
                    if (Vue.prototype.$api) {
                        try {
                            const {data} = await Vue.prototype.$api.put('/api/auth/totp', form);
                            console.log(data);
                            return true;
                        }
                        catch (error) {
                            this.onAxiosError(error, true);
                        }
                    }
                    else {
                        return false;
                    }
                },
                async getCode() {
                    if (Vue.prototype.$api) {
                        try {
                            await Vue.prototype.$api.get('/api/auth/mfa');
                            return true;
                        }
                        catch (error) {
                            this.onAxiosError(error, true);
                        }
                    }
                    else {
                        return false;
                    }
                },
                async submitMfa(form) {
                    if (Vue.prototype.$api) {
                        try {
                            const {data} = await Vue.prototype.$api.post('/api/auth/mfa', form);
                            const {body} = data;
                            const {jwt, info, permissions, meta} = body;
                            this.jwt = jwt;
                            for (let key in info) {
                                this.info[key] = info[key];
                            }
                            while (this.permissions.length > 0) {
                                this.permissions.splice(0, 1);
                            }
                            for (let i = 0; i < permissions.length; i++) {
                                const perm = permissions[i];
                                this.permissions.push(perm);
                            }
                            for (let key in meta) {
                                this.meta[key] = meta[key];
                            }
                            if (Vue.prototype.$CTCM) {
                                Vue.prototype.$CTCM.emit('authentication', body);
                            }
                            if (Vue.prototype.$storage) {
                                Vue.prototype.$storage.set('auth', body);
                            }
                            return true;
                        }
                        catch (error) {
                            this.onAxiosError(error, true);
                        }
                    }
                    else {
                        return false;
                    }
                },
                async logout() {
                    if (Vue.prototype.$api) {
                        try {
                            await Vue.prototype.$api.delete('/api/auth/login');
                        }
                        catch (error) {
                            this.onAxiosError(error, false);
                        }
                    }
                    this.jwt = null;
                    for (let key in this.info) {
                        this.info[key] = '';
                    }
                    while (this.permissions.length > 0) {
                        this.permissions.splice(0, 1);
                    }
                    for (let key in this.meta) {
                        this.meta[key] = false;
                    }
                    if (Vue.prototype.$storage) {
                        Vue.prototype.$storage.set('auth', {
                            jwt: this.jwt,
                            info: this.info,
                            permissions: this.permissions,
                            meta: this.meta
                        });
                    }
                    if (Vue.prototype.$CTCM) {
                        Vue.prototype.$CTCM.emit('authentication', {
                            jwt: this.jwt,
                            info: this.info,
                            permissions: this.permissions,
                            meta: this.meta
                        });
                    }
                }
            },
            created() {
                this.$watch('info', () => {
                    this.$emit('me:updated');
                }, {deep: true});
                if (Vue.prototype.$CTCM) {
                    Vue.prototype.$CTCM.on('authentication', ({jwt, info, permissions, meta}) => {
                        this.jwt = jwt;
                        for (let key in info) {
                            this.info[key] = info[key];
                        }
                        while (this.permissions.length > 0) {
                            this.permissions.splice(0, 1);
                        }
                        for (let i = 0; i < permissions.length; i++) {
                            const perm = permissions[i];
                            this.permissions.push(perm);
                        }
                        for (let key in meta) {
                            this.meta[key] = meta[key];
                        }
                    })
                }
                if (Vue.prototype.$storage) {
                    const body = Vue.prototype.$storage.get('auth');
                    const {jwt, info, permissions, meta} = body;
                    this.jwt = jwt;
                    for (let key in info) {
                        this.info[key] = info[key];
                    }
                    while (this.permissions.length > 0) {
                        this.permissions.splice(0, 1);
                    }
                    for (let i = 0; i < permissions.length; i++) {
                        const perm = permissions[i];
                        this.permissions.push(perm);
                    }
                    for (let key in meta) {
                        this.meta[key] = meta[key];
                    }
                }
            },
            watch: {
                io() {
                    if (null == this.io) {
                        return;
                    }
                    console.debug('$auth hooked to $io');
                    this.io.$on('connected', async () => {
                        console.debug('$auth caught api connection');
                        try {
                            const data = await this.io.request('App/Models/User', 'getMe');
                            const {jwt, info, permissions, meta} = data;
                            this.jwt = jwt;
                            for (let key in info) {
                                this.info[key] = info[key];
                            }
                            while (this.permissions.length > 0) {
                                this.permissions.splice(0, 1);
                            }
                            for (let i = 0; i < permissions.length; i++) {
                                const perm = permissions[i];
                                this.permissions.push(perm);
                            }
                            for (let key in meta) {
                                this.meta[key] = meta[key];
                            }
                        }
                        catch (error) {
                            await this.logout();
                        }
                    })
                    this.io.$on('me:updated', async () => {
                        console.debug('$auth caught me:updated');
                        try {
                            const data = await this.io.request('App/Models/User', 'getMe');
                            const {jwt, info, permissions, meta} = data;
                            this.jwt = jwt;
                            for (let key in info) {
                                this.info[key] = info[key];
                            }
                            while (this.permissions.length > 0) {
                                this.permissions.splice(0, 1);
                            }
                            for (let i = 0; i < permissions.length; i++) {
                                const perm = permissions[i];
                                this.permissions.push(perm);
                            }
                            for (let key in meta) {
                                this.meta[key] = meta[key];
                            }
                        }
                        catch (error) {
                            await this.logout();
                        }
                    });
                    this.io.$on('logout', async (reason) => {
                        console.debug('$auth caught logout');
                        if (reason) {
                            const {title, text, icon} = reason;
                            if (Vue.prototype.$Swal) {
                                Vue.prototype.$Swal.fire({title, text, icon});
                            }
                        }
                        await this.logout();
                    });
                }
            }
        })
    }

    get instance() {
        return this.#instance;
    }

    static install (Vue, config) {
        const instance = new this(Vue, config);
        Vue.prototype.$auth = instance.instance;
    }
}

export default ModelManager;