<template>
    <v-sheet color="transparent" class="px-2">
        <mobileBoard
            v-for="(board, i) in boards"
            :key="i"
            :board="board"
            :stats="stats"
            :moment="moment"
            :monthProgress="monthProgress"
            @expand="onExpand"
        ></mobileBoard>
        <v-dialog :value="showExpand" fullscreen>
            <verticalBoard v-if="showExpand" :board="expanded" :stats="stats" :moment="moment" :monthProgress="monthProgress" @close="onClose" :mobile="false" :showClose="true"></verticalBoard>
        </v-dialog>
    </v-sheet>
</template>

<script>
import mobileBoard from '../../../components/scoreboard/slideLayouts/mobile';
import verticalBoard from '../../../components/scoreboard/slideLayouts/vertical';
export default {
    components: {
        mobileBoard,
        verticalBoard,
    },
    props: {
        boards: {
            type: Array,
            required: true,
        },
        stats: {
            type: Object,
            required: true,
        },
        moment: {},
        monthProgress: {},
    },
    data: () => ({
        expanded: null,
    }),
    computed: {
        showExpand() {
            return (null !== this.expanded);
        }
    },
    methods: {
        onExpand(board) {
            this.expanded = board;
        },
        onClose() {
            this.expanded = null;
        }
    },
}
</script>