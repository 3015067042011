/**
 * Wrapper for Socket.IO which makes it compatible with Vue using native Vue hooks etc.
 */
const io = require('socket.io-client');
const shortid = require('shortid');
const DEFAULT_TIMEOUT = 5000;
class IO {
    #component;
    #private;
    #io;
    #host;
    #jwt;

    constructor(Vue, config) {
        const that = this;
        const timeout = async (time) => {
            if (!time) {
                time = DEFAULT_TIMEOUT;
            }
            time = parseInt(time);
            if ((time < 0 || isNaN(time)) && config) {
                time = parseInt(config.timeout);
            }
            if (time < 0 || isNaN(time)) {
                time = DEFAULT_TIMEOUT;
            }
            const promise = new Promise((resolve, reject) => {
                setTimeout(() => {
                    reject('Timeout');
                }, time)
            })
            return promise;
        }
        this.#private = new Vue({
            data: () => ({
                connected: false,
                initialized: false,
                host: '',
            }),
        })
        this.#component = new Vue({
            methods: {
                async connect(host, jwt) {
                    return await that.connect(host, jwt);
                },
                async disconnect() {
                    return await that.disconnect();
                },
                async request(model, method, args, ttl) {
                    const id = shortid.generate();
                    const promise = new Promise((resolve) => {
                        that.io.on(['response', id].join('_'), (payload) => {
                            resolve(JSON.parse(payload));
                        })
                    })
                    const errorPromise = new Promise((resolve, reject) => {
                        that.io.on(['error', id].join('_'), (payload) => {
                            reject(payload);
                        })
                    })
                    that.io.emit('request', {
                        id,
                        model,
                        method,
                        args
                    })
                    return await Promise.race([promise, errorPromise, timeout(ttl)]);
                }
            },
            computed: {
                connected() {
                    return that.private.connected;
                },
                initialized() {
                    return that.private.initialized;
                },
                secure() {
                    return that.private.host.startsWith('https');
                },
                host() {
                    return that.private.host;
                }
            }
        })
    }

    async connect(host, jwt) {
        if (host) {
            this.#host = host;
            this.#private.host = host;
        }
        if (jwt) {
            this.#jwt = jwt;
        }
        if (!this.#host || !this.#jwt) {
            throw new Error('missing.credentials');
        }
        this.#io = io(this.#host, {
            query: {
                jwt: this.#jwt,
            },
            forceNew: true,
        });
        this.#io.onAny((event, ...args) => {
            if (!event.startsWith('error_') && !event.startsWith('response_')) {
                this.#component.$emit.apply(this.#component, [event].concat(args));
            }
        });
        this.#io.on('connect', () => {
            this.#private.connected = true;
            this.#component.$emit('connected');
        })
        this.#io.on('disconnect', () => {
            this.#private.connected = false;
            this.#component.$emit('disconnected');
        })
        this.#io.on('connect_error', (error) => {
            this.#private.connected = false;
            if (error.toString() !== 'Error: xhr poll error') {
                this.disconnect();
                this.#component.$emit('connect_error', error);
            }
        })
        this.#private.initialized = true;
        return true;
    }

    async disconnect() {
        if (this.#io) {
            this.#io.disconnect();
        }
        this.#io = null;
        this.#private.initialized = false;
        return true;
    }

    get component() {
        return this.#component;
    }

    get private() {
        return this.#private;
    }

    get connected() {
        if (!this.#io) {
            return false;
        }
        return this.#io.connected;
    }

    get io() {
        return this.#io;
    }

    static install (Vue, config) {
        const instance = new this(Vue, config);
        Vue.prototype.$io = instance.component;
    }
}

export default IO;