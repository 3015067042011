<template>
    <div class="f-fitted-wrapper" ref="wrapper" v-resize="onResize" v-bind:style="outerWrapperStyle">
        <div class="f-fitted-inner-wrapper" v-bind:style="innerWrapperStyle">
            <span class="f-fitted-content" ref="content">
                <slot name="content"></slot>
            </span>
        </div>
        <span class="debug-cell" v-text="debug"></span>
        <span class="ratio-cell" v-text="textContent" ref="ratio"></span>
    </div>
</template>

<script>
export default {
    props: {
        options: {
            type: Object,
        }
    },
    data: () => ({
        width: 0,
        height: 0,
        contentHeight: 0,
        contentWidth: 0,
        intervals: {},
        u: 0,
    }),
    computed: {
        debug() {
            return [
                [this.width, this.height].join('x'),
                [this.contentWidth, this.contentHeight].join('x'),
                this.contentRatioHeight,
            ].join(' ');
        },
        outerWrapperStyle() {
            const ret = {};
            if (this.options && this.options.maxSize) {
                ret.height = [this.options.maxSize, 'px'].join('');
            }
            return ret;
        },
        innerWrapperStyle() {
            return {
                lineHeight: [this.height, 'px'].join(''),
                fontSize: this.fontSize,
            }
        },
        textContent() {
            this.u;
            if (this.$refs.content) {
                return this.$refs.content.innerText;
            }
            return '';
        },
        contentRatio() {
            if (this.contentHeight > 0) {
                return this.contentWidth / this.contentHeight;
            }
            return 0;
        },
        contentRatioHeight() {
            return (this.width / this.contentRatio) * 0.8;
        },
        fontSize() {
            if ((this.height * 0.8) > this.contentRatioHeight) {
                return [this.contentRatioHeight, 'px'].join('');
            }
            return [(this.height * 0.8), 'px'].join('');
        }
    },
    methods: {
        onResize() {
            if (this.$refs.wrapper) {
                this.width = this.$refs.wrapper.offsetWidth;
                this.height = this.$refs.wrapper.offsetHeight;
            }
            this.$nextTick(() => {
                if (this.$refs.ratio) {
                    this.contentWidth = this.$refs.ratio.offsetWidth;
                    this.contentHeight = this.$refs.ratio.offsetHeight;
                }
            })
        }
    },
    mounted() {
        this.onResize();
        this.intervals.size = setInterval(() => {
            this.onResize();
        }, 1000);
        this.$nextTick(() => {
            this.u ++;
            this.onResize();
        })
    },
    beforeDestroy() {
        for (let i in this.intervals) {
            clearInterval(this.intervals[i]);
        }  
    },
}
</script>

<style lang="scss">
    .v-application {
        .f-fitted-wrapper {
            position: relative;
            display: block;
            width: 100%;
            height: 100%;
            overflow-x: hidden;
            overflow-y: hidden;
            min-height: 30px;

            .f-fitted-inner-wrapper {
                font-size: initial;
                line-height: initial;
                position: relative;
                overflow-y: hidden;

                .f-fitted-content {
                    display: inline-block;
                }
            }

            .debug-cell {
                position: absolute;
                top: 0;
                right: 0;
                font-size: 8px;
                height: 10px;
                line-height: 10px;
                display: inline;
                opacity: 0;
                float: right;
            }

            .ratio-cell {
                float: left;
                position: absolute;
                top: 0;
                left: 0;
                font-size: 8px !important;
                height: 10px;
                line-height: 10px;
                display: inline;
                opacity: 0;
            }
        }
    }
</style>