<template>
    <v-dialog :value="!$config.host" max-width="400" persistent :overlay-opacity="1" overlay-color="background">
        <validation-observer slim ref="observer" v-slot="{ invalid }">
            <v-card color="panel">
                <v-toolbar color="toolbar" dense flat>
                    <v-toolbar-title>Feriority Stats</v-toolbar-title>
                </v-toolbar>
                <v-divider color="white"></v-divider>
                <v-card-text class="pt-3">
                    <validation-provider v-slot="{ errors }" name="Stats Host" rules="required|url">
                        <v-text-field type="url" v-model.trim="form.host" :loading="loading" :disabled="loading" :error-messages="errors" label="Stats Host" required clearable cb="login" @keydown="cbIfEnter"></v-text-field>
                    </validation-provider>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn color="primary" text :disabled="invalid" :loading="loading" @click="login">
                    <span>Save</span>
                    <v-icon :class="$vuetify.rtl ? 'mr-2' : 'ml-2'" v-text="$vuetify.rtl ? 'mdi-chevron-left' : 'mdi-chevron-right'"></v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </validation-observer>
    </v-dialog>
</template>

<script>
export default {
  data: () => ({
    form: {
        host: '',
    },
    loading: false,
  }),
  methods: {
      async login() {
        const valid = await this.$refs.observer.validate();
        if (!valid) {
            console.warn(`Got Errors:`, this.$refs.observer.errors);
            return false;
        }
        this.loading = true;
        this.$storage.set('host', this.form.host);
        this.$config.host = this.form.host;
        this.$config.$emit('loaded');
        this.loading = false;
      }
  },
};
</script>
