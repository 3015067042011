<template>
    <highcharts :options="co" :key="u"></highcharts>
</template>

<script>
import Highcharts from 'highcharts'
export default {
    props: {
        targets: {
            type: Object,
            default: () => ({
                cpa: 0,
                ftds: 0,
                spend: 0,
            }),
        },
        external: {
            type: Boolean,
            default: true,
        },
        spend: {
            type: [Object, Number],
            default: null,
        },
        cpa: {
            type: [Object, Number],
            default: null,
        },
        ftds: {
            type: [Object, Number],
            default: null,
        },
        maxHeightPercent: {
            type: Number,
            default: 0.19,
        },
        maxHeight: {
            type: Number,
        }
    },
    data: () => ({
        viewportHeight: window.innerHeight,
        u: 0,
    }),
    computed: {
        _ctr() {
            // FTDs
            if ('undefined' == typeof this.targets.ftds || null == this.targets.ftds) {
                return 100;
            }
            if (0 == this.targets.ftds) {
                if (this.ftds > 0) {
                    return 100;
                }
                else {
                    return 0;
                }
            }
            if (null == this.ftds) {
                return 0;
            }
            return Math.round((this.ftds / this.targets.ftds)*100)
        },
        _ctl() {
            // CPA
            if ('undefined' == typeof this.targets.cpa || null == this.targets.cpa) {
                return 100;
            }
            if (0 == this.targets.cpa) {
                if (this.cpa > 0) {
                    return 100;
                }
                else {
                    return 0;
                }
            }
            if (null == this.cpa) {
                return 0;
            }
            return Math.round((this.cpa / this.targets.cpa)*100)
        },
        _cr() {
            // Spend
            if ('undefined' == typeof this.targets.spend || null == this.targets.spend) {
                return 100;
            }
            if (0 == this.targets.spend) {
                if (this.spend > 0) {
                    return 100;
                }
                else {
                    return 0;
                }
            }
            if (null == this.spend) {
                return 0;
            }
            return Math.round((this.spend / this.targets.spend)*100)
        },
        co() {
            const ret = {
                chart: {
                    backgroundColor: 'transparent',
                    type: 'solidgauge',
                    height: '110%',
                    style: {
                        maxHeight: (this.maxHeight) ? `${this.maxHeight}px` : `${this.viewportHeight * this.maxHeightPercent}px`,
                    }
                },
                title: {
                    text: '',
                    style: {
                        color: 'silver',
                        fontSize: '1px'
                    }
                },
                colors: ['#C62828', '#689F38', '#0277BD'],
                tooltip: {
                    borderWidth: 0,
                    backgroundColor: Highcharts.color('#1d1d1f')
                                .setOpacity(0.95)
                                .get(),
                    shadow: false,
                    style: {
                        color: 'white',
                        fontSize: '10px'
                    },
                    valueSuffix: '%',
                    pointFormat: '{series.name}<br><span style="font-size:2em; color: font-weight: bold">{point.y}</span>',
                    positioner: function (labelWidth) {
                        return {
                            x: (this.chart.chartWidth - labelWidth) / 2,
                            y: (this.chart.plotHeight / 2) + 15
                        };
                    }
                },
                credits: {
                    enabled: false,
                },
                pane: {
                    startAngle: 0,
                    endAngle: 360,
                    background: [
                        { // Track for Daily CTR
                            outerRadius: '112%',
                            innerRadius: '88%',
                            backgroundColor: Highcharts.color('#C62828')
                                .setOpacity(0.3)
                                .get(),
                            borderWidth: 0
                        },
                        { // Track for Daily CTL
                            outerRadius: '87%',
                            innerRadius: '63%',
                            backgroundColor: Highcharts.color('#689F38')
                                .setOpacity(0.3)
                                .get(),
                            borderWidth: 0
                        },
                        { // Track for Daily CR
                            outerRadius: '62%',
                            innerRadius: '38%',
                            backgroundColor: Highcharts.color('#0277BD')
                                .setOpacity(0.3)
                                .get(),
                            borderWidth: 0
                        },
                    ].filter((v, i) => {
                        if (this.external) {
                            return true;
                        }
                        else {
                            return (0 == i)
                        }
                    })
                },
                yAxis: {
                    min: 0,
                    max: 100,
                    lineWidth: 0,
                    tickPositions: []
                },
                plotOptions: {
                    solidgauge: {
                        dataLabels: {
                            enabled: false
                        },
                        linecap: 'round',
                        stickyTracking: false,
                        rounded: true
                    }
                },
                series: [
                    {
                        name: 'FTD Target',
                        data: [{
                            color: '#C62828',
                            radius: '112%',
                            innerRadius: '88%',
                            y: this._ctr
                        }]
                    },
                    {
                        name: 'CPA Target',
                        data: [{
                            color: '#689F38',
                            radius: '87%',
                            innerRadius: '63%',
                            y: this._ctl
                        }]
                    },
                    {
                        name: 'Spend Target',
                        data: [{
                            color: '#0277BD',
                            radius: '62%',
                            innerRadius: '38%',
                            y: this._cr
                        }]
                    },
                ].filter((v, i) => {
                    if (this.external) {
                        return true;
                    }
                    else {
                        return (0 == i)
                    }
                })
            };
            return ret;
        }
    },
    methods: {
        onResize() {
            this.viewportHeight = window.innerHeight
        }
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
    },
    beforeDestroy() { 
        window.removeEventListener('resize', this.onResize); 
    },
    watch: {
        viewportHeight() {
            this.u ++;
        }
    }
}
</script>