<template>
    <v-dialog :value="true" max-width="400" persistent overlay-color="background">
        <validation-observer slim ref="observer" v-slot="{ invalid }">
            <v-card color="panel">
                <v-toolbar color="toolbar" dense flat class="remove-end-padding">
                    <v-toolbar-title>Security</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn text @click="$emit('close')">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-divider color="white"></v-divider>
                <v-card-text class="pt-3">
                    <validation-provider v-slot="{ errors }" name="Current Password" rules="required">
                        <v-text-field type="password" v-model.trim="form.currentPass" :loading="loading" :disabled="loading" :error-messages="errors" label="Current Password" required :clearable="!loading" cb="login" @keydown="cbIfEnter"></v-text-field>
                    </validation-provider>
                    <validation-provider v-slot="{ errors }" name="New Password" rules="required" vid="confirmation">
                        <v-text-field type="password" v-model.trim="form.newPass" :loading="loading" :disabled="loading" :error-messages="errors" label="New Password" required :clearable="!loading" cb="login" @keydown="cbIfEnter"></v-text-field>
                    </validation-provider>
                    <validation-provider v-slot="{ errors }" name="New Password Confirmation" rules="required|confirmed:confirmation">
                        <v-text-field type="password" v-model.trim="form.newPassAgain" :loading="loading" :disabled="loading" :error-messages="errors" label="Confirm New Password" required :clearable="!loading" cb="login" @keydown="cbIfEnter"></v-text-field>
                    </validation-provider>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn color="primary" text :disabled="invalid || !$io.connected" :loading="loading" @click="login">
                    <span>Save</span>
                    <v-icon :class="$vuetify.rtl ? 'mr-2' : 'ml-2'" v-text="$vuetify.rtl ? 'mdi-chevron-left' : 'mdi-chevron-right'"></v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </validation-observer>
    </v-dialog>
</template>

<script>
export default {
  data: () => ({
    form: {
        currentPass: '',
        newPass: '',
        newPassAgain: '',
    },
    loading: false,
  }),
  computed: {
      
  },
  methods: {
      async login() {
        const valid = await this.$refs.observer.validate();
        if (!valid) {
            console.warn(`Got Errors:`, this.$refs.observer.errors);
            return false;
        }
        this.loading = true;
        try {
            await this.$io.request('App/Models/User', 'updateSecurityDetails', this.form);
        }
        catch (error) {
            this.onIoRequestError(error, true);
        }
        this.loading = false;
        this.$emit('close');
      },
  },
  mounted() {
      
  },
};
</script>
