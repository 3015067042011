<template>
    <v-sheet style="position: relative;" color="background" class="fill-height">
        <mobile v-if="$vuetify.breakpoint.smAndDown && settings.options.boards.length > 0" v-bind="bind"></mobile>
        <desktop v-else-if="settings.options.boards.length > 0" v-bind="bind"></desktop>
        <v-container class="h-100" v-if="0 == settings.options.boards.length && !loading">
            <v-row class="h-100" align="center">
                <v-col cols="12" md="6" lg="4" offset-md="3" offset-lg="4">
                    <v-card color="panel" elevation="11">
                        <v-toolbar color="toolbar" tile dense flat>
                            <v-toolbar-title>No Boards Available</v-toolbar-title>
                        </v-toolbar>
                        <v-divider color="white"></v-divider>
                        <v-card-text class="pa-3">
                            <span>You do not have permission to view any scoreboards. Please check with a system administrator for more information.</span>
                        </v-card-text>
                        <v-card-actions class="px-3">
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" :disabled="!$io.connected || loading" :loading="loading" @click="loadAll">
                                <span>Refresh</span>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-btn small color="secondary" dark fab fixed app style="bottom: 30px; right: 5px; z-index: 101;" @click="settings.show = !settings.show">
            <v-icon>mdi-cogs</v-icon>
        </v-btn>
        <v-dialog v-model="settings.show" fullscreen>
            <v-card tile color="panel">
                <v-toolbar color="toolbar" class="remove-end-padding" flat dense>
                    <v-toolbar-title>
                    <v-icon :class="$vuetify.rtl ? 'ml-2' : 'mr-2'">mdi-cogs</v-icon>
                    <span>Scoreboard Settings</span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn text @click="settings.show = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                         <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list dense>
                                <v-list-item @click="loadAll" :disabled="!$io.connected || loading" :loading="loading">
                                    <v-list-item-avatar>
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>Refresh All Data</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item @click="getAllBoards" :disabled="!$io.connected || loading" :loading="loading">
                                    <v-list-item-avatar>
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>Refresh Available Scoreboards</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item @click="getBoardData" :disabled="!$io.connected || loading" :loading="loading">
                                    <v-list-item-avatar>
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>Refresh Scoreboard Data</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                         </v-menu>
                    </v-toolbar-items>
                </v-toolbar>
                <v-divider color="white"></v-divider>
                <v-card-text class="py-0">
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-if="!$vuetify.breakpoint.smAndDown"
                                v-model.number="settings.form.cycle"
                                label="Carousel Slide Interval"
                                :loading="loading"
                            >
                            </v-text-field>
                            <checklist
                                v-model="settings.form.boards"
                                :items="settings.options.boards" 
                                label="Scoreboards to Show"
                                :loading="loading"
                                autoable
                            >
                            </checklist>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-overlay :value="statsLoading" app absolute>
            <v-progress-circular
                indeterminate
                size="128"
                color="secondary"
                :opacity="0.9"
                :zIndex="100"
            >
                Loading
            </v-progress-circular>
        </v-overlay>
    </v-sheet>
</template>

<script>
import desktop from './layouts/desktop';
import mobile from './layouts/mobile';
import checklist from '../../components/general/checklist';

import shortid from 'shortid';
import moment from 'moment-timezone';

export default {
    components: {
        desktop,
        mobile,
        checklist,
    },
    data: () => ({
        settings: {
            show: false,
            form: {
                boards: true,
                cycle: 15,
            },
            options: {
                boards: [],
            },
        },
        loaded: {
            boards: false,
        },
        statsLoading: true,
        requests: {},
        stats: {},
        moment: null,
        intervals: {},
        lu: null,
    }),
    computed: {
        bind() {
            return {
                boards: this._boards,
                stats: this.stats,
                cycle: this.settings.form.cycle * 10000,
                moment: this.tzm,
                monthProgress: this.monthProgress,
            };
        },
        _boards() {
            const src = ('undefined' == typeof this.settings.form.boards || true == this.settings.form.boards) ? this.settings.options.boards : this.settings.options.boards.filter((v) => {
                return (this.settings.form.boards.indexOf(v.value) > -1);
            });
            return [... new Set(src)];
        },
        loading() {
            let ret = false;
            for (let key in this.loaded) {
                if (false == this.loaded[key]) {
                    ret = true;
                }
            }
            return ret;
        },
        tzm() {
            if (this.moment) {
                const tz = (this.$auth && this.$auth.info && this.$auth.info.timezone) ? this.$auth.info.timezone : 'UTC';
                return this.moment.clone().tz(tz);
            }
            return null;
        },
        monthProgress() {
            if (!this.tzm) {
                return 0;
            }
            const total = parseInt(this.tzm.daysInMonth());
            const current = parseInt(this.tzm.format('DD'));
            const division = current / total;
            return division * 100;
        },
    },
    methods: {
        async loadAll() {
            await Promise.all([
                this.getAllBoards(),
                this.getBoardData()
            ]);
        },
        async getAllBoards() {
            if (!this.$io.connected) {
                return false;
            }
            this.loaded.boards = false;
            const id = shortid.generate();
            this.requests.loadBoards = id;
            try {
                const res = await this.$io.request('App/Models/Scoreboard', 'getMyBoards', [], 60000);
                if (this.requests.loadBoards == id) {
                    this.settings.options.boards = res.boards;
                    this.loaded.boards = true;
                }
            }
            catch (error) {
                if (this.requests.loadBoards == id) {
                    this.$PNotify.error({
                        title: 'Error',
                        text: error.toString(),
                    })
                    this.loaded.boards = true;
                }
            }
        },
        async getBoardData() {
            if (!this.$io.connected) {
                return false;
            }
            const id = shortid.generate();
            this.requests.loadData = id;
            this.statsLoading = true;
            try {
                const res = await this.$io.request('App/Models/Scoreboard', 'getMyBoardData', [], 60000);
                if (this.requests.loadData == id) {
                    this.stats = res;
                    this.lu = moment();
                    this.statsLoading = false;
                }
            }
            catch (error) {
                if (this.requests.loadData == id) {
                    this.$PNotify.error({
                        title: 'Error',
                        text: error.toString(),
                    })
                }
            }
        },
        handleSBS(data) {
            this.stats = data;
            this.lu = moment();
            this.statsLoading = false;
        }
    },
    mounted() {
        this.moment = moment();
        this.intervals.moment = setInterval(() => {
            this.moment = moment();
        }, 500);
        this.intervals.loadBoards = setInterval(() => {
            this.getAllBoards();
        }, 60000);
        this.loadAll();
        this.$io.$on('connected', this.loadAll);
        this.$io.$on('sbs', this.handleSBS);
        if (this.$route && this.$route.query) {
            if ('undefined' == typeof this.$route.query.boards || 'true' == this.$route.query.boards) {
                this.settings.form.boards = true;
            }
            else if ('string' == typeof this.$route.query.boards) {
                this.settings.form.boards = [];
                this.settings.form.boards.push(this.$route.query.boards);
            }
            else if (Array.isArray(this.$route.query.boards)) {
                this.settings.form.boards = [];
                for (let i = 0; i < this.$route.query.boards.length; i++) {
                    const board = this.$route.query.boards[i];
                    this.settings.form.boards.push(board);
                }
            }
        }
        this.$watch('settings.form', () => {
            this.$router.push({
                name: 'Home',
                query: this.settings.form,
            })
        }, {
            deep: true,
        })
    },
    beforeDestroy() {
        for (let i in this.intervals) {
            clearInterval(this.intervals[i]);
        }
        this.$io.$off('connected', this.loadAll);
        this.$io.$off('sbs', this.handleSBS);
    },
}
</script>

<style lang="scss">
    .v-application.theme--dark {
        .sb-board {
            .sb-cell-value, 
            .sb-cell-label,
            .target-content-only,
            .target-content-current,
            .target-content-goal {
                color: #FFFFFF;
            }    
        }
    }
    .sb-board {
        .sb-cell-value {
            display: block;
        }

        .sb-cell-value, 
        .sb-cell-label,
        .target-content-only,
        .target-content-current,
        .target-content-goal {
            color: #222222;
        }

        &.desktop {
            .sb-cell-value {
                text-align: start;
                font-size: 3vh;
                line-height: 5.5vh;

                @media (max-width: 1024px) {
                    line-height: 5vh;
                }

                white-space: nowrap;
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                flex-direction: row-reverse;
                padding-right: 5px;

                &:after {
                    content: '';
                    position: absolute;
                    right: 12px;
                    font-weight: bold;
                    font-size: 8px;
                }

                &.day {
                    &:after {
                        content: 'D';
                    }
                }

                &.month {
                    &:after {
                        content: 'M';
                    }
                }
            }

            &.desktop-double {
                .sb-cell-value {
                    line-height: 18vh;
                }
            }
        }

        &.vertical {
            .sb-cell-value {
                text-align: end;
                font-size: 4.5vh;
                line-height: 5.0vh;
                white-space: nowrap;
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                flex-direction: row-reverse;
            }
        }

        &.mobile {
            .sb-cell-label {
                font-size: 10px;
            }

            .sb-cell-value {
                text-align: end;
                font-size: 15px;
                line-height: 25px;
                white-space: nowrap;
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                flex-direction: row-reverse;
            }
        }
    }
</style>