<template>
    <v-dialog :value="value" persistent overlay-color="background" fullscreen>
        <validation-observer slim ref="observer" v-slot="{}">
            <v-card color="panel" class="d-flex flex-column">
                <v-toolbar color="toolbar" dense flat class="remove-end-padding flex-grow-0">
                    <v-toolbar-title>Chatbot</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn text @click="$emit('close')">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-divider color="white"></v-divider>
                <v-card-text class="pa-0 background flex-grow-1 d-flex align-stretch">
                    <div class="chatbot-chat-window" ref="cbwindow">
                        <ul>
                            <li v-for="(msg, mi) in messages" :key="mi" :class="`src-${msg.src}`">
                                <v-btn v-if="'remote' == msg.src" fab color="toolbar" dark :class="$vuetify.rtl ? 'ml-2 elevation-8' : 'mr-2 elevation-8'" small @click="copyText(msg.raw)">
                                    <v-icon>mdi-content-copy</v-icon>
                                </v-btn>
                                <v-sheet :color="'local' == msg.src ? 'toolbar' : 'toolbar'" v-html="msg.html" :class="`elevation-8`"></v-sheet>
                                <v-btn v-if="'local' == msg.src" fab color="toolbar" dark :class="$vuetify.rtl ? 'mr-2 elevation-8' : 'ml-2 elevation-8'" small @click="send(msg.raw)" :disabled="!$io.connected">
                                    <v-icon>mdi-refresh</v-icon>
                                </v-btn>
                                <v-btn v-if="'local' == msg.src" fab color="toolbar" dark :class="$vuetify.rtl ? 'mr-2 elevation-8' : 'ml-2 elevation-8'" small @click="form.message = msg.raw">
                                    <v-icon>mdi-content-copy</v-icon>
                                </v-btn>
                            </li>
                        </ul>
                    </div>
                </v-card-text>
                <v-divider color="white"></v-divider>
                <v-toolbar color="toolbar" dense flat class="remove-padding flex-grow-0">
                    <v-toolbar-items>
                        <v-btn icon @click="send('/help')" :loading="loading" :disabled="!$io.connected">
                            <v-icon>mdi-help-circle</v-icon>
                        </v-btn>
                        <v-btn icon @click="send('/callcenter')" :loading="loading" :disabled="!$io.connected">
                            <v-icon>mdi-face-agent</v-icon>
                        </v-btn>
                        <v-btn icon @click="send('/stats')" :loading="loading" :disabled="!$io.connected">
                            <v-icon>mdi-chart-box</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-divider color="white"></v-divider>
                <v-card-text class="pa-3 flex-grow-0 toolbar">
                    <validation-provider v-slot="{ errors }" name="Message" rules="required">
                        <v-text-field
                            type="text"
                            v-model.trim="form.message"
                            :loading="loading"
                            :disabled="loading || !$io.connected"
                            :error-messages="errors"
                            :hide-details="errors.length == 0"
                            label="Message"
                            required
                            :clearable="!loading"
                            cb="submit"
                            @keydown="cbIfEnter"
                            append-icon="mdi-send"
                            @click:append="submit"
                            ref="formField"
                        ></v-text-field>
                    </validation-provider>
                </v-card-text>
            </v-card>
        </validation-observer>
    </v-dialog>
</template>

<script>
import marked from 'marked';
import DOMPurify from 'dompurify';

export default {
    props: {
        value: {
            type: Boolean,
            default: false,
        }
    },
    data: () => ({
        form: {
            message: '',
        },
        loading: false,
        messages: [],
    }),
    computed: {},
    methods: {
        async send(msg) {
            this.form.message = msg;
            this.$nextTick(() => {
                this.submit();
            })
        },
        async submit() {
            const valid = await this.$refs.observer.validate();
            if (!valid) {
                console.warn(`Got Errors:`, this.$refs.observer.errors);
                return false;
            }
            this.loading = true;
            try {
                await this.$io.request('App/Models/SocketIoChatbot', 'submit', [this.form.message]);
                this.messages.push({
                    src: 'local',
                    html: DOMPurify.sanitize(marked(this.form.message)),
                    raw: this.form.message,
                });
                this.form.message = '';
                this.$refs.observer.reset();
                this.$nextTick(() => {
                    this.$refs.formField.focus();
                    if (this.$refs.cbwindow) {
                        if (Array.isArray(this.$refs.cbwindow)) {
                            for (let i = 0; i < this.$refs.cbwindow.length; i++) {
                                this.$refs.cbwindow[i].scrollTop = this.$refs.cbwindow[i].scrollHeight;
                            }
                        }
                        else {
                            this.$refs.cbwindow.scrollTop = this.$refs.cbwindow.scrollHeight;
                        }
                    }
                })
            }
            catch (error) {
                this.onIoRequestError(error, true);
            }
            this.loading = false;
        },
        onCopySuccess() {
            this.$PNotify.success({
                title: 'Copied to Clipboard'
            })
        },
        onCopyError() {
            this.$$PNotify.error({
                title: 'Failed to copy to clipboard'
            })
        },
        copyText(msg) {
            this.$copyText(msg).then(this.onCopySuccess).catch(this.onCopyError);
        },
        handleMessage(message) {
            if (message) {
                this.messages.push({
                    src: 'remote',
                    html: DOMPurify.sanitize(marked(message)),
                    raw: message,
                });
            }
            this.$nextTick(() => {
                if (this.$refs.cbwindow) {
                    if (Array.isArray(this.$refs.cbwindow)) {
                        for (let i = 0; i < this.$refs.cbwindow.length; i++) {
                            this.$refs.cbwindow[i].scrollTop = this.$refs.cbwindow[i].scrollHeight;
                        }
                    }
                    else {
                        this.$refs.cbwindow.scrollTop = this.$refs.cbwindow.scrollHeight;
                    }
                }
            })
        }
    },
    mounted() {
        this.$io.$on('cbresponse', this.handleMessage);
    },
    beforeDestroy() {
        this.$io.$off('cbresponse', this.handleMessage);
    },
};
</script>


<style lang="scss">
.chatbot-chat-window {
    width: 100%;
    overflow-y: auto;
    height: calc(100vh - 49px - 49px - 94px);
    max-width: 100vw;

    > ul {
        list-style-type: none;
        padding: 0;

        > li {
            padding: 5px 10px;
            display: flex;
            width: 100%;

            &.src-local {
                justify-content: flex-end;

                > .v-sheet {
                    max-width: calc(100vw - 50px - 50px);
                }
            }

            > .v-sheet {
                max-width: calc(100vw - 50px);
                display: inline-block;
                padding: 16px;
                border-radius: 10px;

                ul {
                    margin-bottom: 10px;
                }

                > *:last-child {
                    margin-bottom: 0;
                }

                pre {
                    white-space: pre-wrap;
                }
            }
        }
    }
}
</style>