import shortid from 'shortid';
import Triggerable from './src/triggerable';
// import {BroadcastChannel, md5} from '../src/required';

const {BroadcastChannel} = require('broadcast-channel');
const md5 = require('md5');

/**
 * The Cross-Tab Communication Manager uses socket.io client-like APIs to communicate and pass information between tabs
 */

class CTCM extends Triggerable {
    #id = null;             // the tab ID used to figure out which tab is the leader
    #leader = true;         // if the current tab is the leader (main tab which should be used for specific types of events)
    #channel = null;        // the actual broadcast channel
    #hidden = false;        // if the current window is hidden 
    

    /**
     * Create a CTCM Instance
     * @param {Object} options Object with boot-up options
     * @param {String} options.channel The name of the channel to be used. Defaults to md5 hash of the window origin
     */
    constructor(options) {
        super();
        const defaultOptions = {
            channel: md5(window.location.origin)
        };
        const opts = Object.assign({}, defaultOptions, options);
        this.#id = shortid.generate();
        this.#channel = new BroadcastChannel(opts.channel);
        console.debug(`[CTCM] Initialized with Channel Name ${opts.channel} and Tab ID ${this.#id}`);
        this.#channel.onmessage = (msg) => {
            try {
                const payload = JSON.parse(msg);
                if (payload.leader) {
                    if (payload.leader == this.#id) {
                        this.#leader = true;
                        this.trigger('leader', true);
                        console.debug(`[CTCM] Leader is now set to true`);
                    }
                    else {
                        this.#leader = false;
                        this.trigger('leader', false);
                        console.debug(`[CTCM] Leader is now set to false`);
                    }
                }
                if (payload.event) {
                    const {event, args} = payload;
                    const triggerArgs = [event].concat(args);
                    console.debug(`[CTCM] Recieved Event ${event}`);
                    this.trigger.apply(this, triggerArgs);
                }
            }
            catch (error) {
                console.warn(`CTCM Error: Could not understand message. ${error.toString()}`, error);
            }
        }
        this.#hidden = document.hidden;
        console.debug(`[CTCM] Hidden is now set to ${this.#hidden}`);
        this.#channel.postMessage(JSON.stringify({
            leader: this.#id,
        }))
        document.addEventListener('visibilitychange', () => {
            this.#hidden = document.hidden;
            console.debug(`[CTCM] Hidden is now set to ${this.#hidden}`);
            if (!this.#hidden) {
                this.#leader = true;
                this.trigger('leader', true);
                console.debug(`[CTCM] Leader is now set to true`);
                this.#channel.postMessage(JSON.stringify({
                    leader: this.#id,
                }))
            }
        })
        window.addEventListener('focus', () => {
            this.#hidden = document.hidden;
            if (!this.#leader) {
                console.debug(`[CTCM] Leader is now set to true`);
            }
            this.#leader = true;
            this.trigger('leader', true);
            this.#channel.postMessage(JSON.stringify({
                leader: this.#id,
            }))
        })
        this.on('get-leader-request', () => {
            if (this.#leader) {
                this.emit('get-leader-response', this.#id);
            }
        })
        this.on('get-all-tabs-request', () => {
            this.emit('get-all-tabs-response', this.#id);
        })
    }

    /**
     * Get the status of if the current tab is the leader, with option to take over the role of leader if there is no current leader
     * A state of no leadership can occur when the tab which was leader gets closed without another tab taking focus
     * @param {Boolean} acceptNomination Accept nomination for leader if there is no current leader
     */
    async getIsLeader(acceptNomination) {
        if (this.#leader) {
            return true;
        }
        const response = new Promise((resolve) => {
            this.once('get-leader-response', (id) => {
                resolve(id);
            })
            setTimeout(() => {
                resolve(false)
            }, 1000);
        })
        this.emit('get-leader-request');
        let lid = await response;
        if (!lid && acceptNomination) {
            console.debug(`[CTCM] No leader has been found. Accepting nomination as leader.`);
            this.#leader = true;
            this.trigger('leader', true);
            this.#channel.postMessage(JSON.stringify({
                leader: this.#id,
            }))
            lid = this.#id;
        }
        else {
            console.debug(`[CTCM] A leader has been found.`)
        }
        return (lid == this.#id);
    }

    async getAllTabs() {
        const response = new Promise((resolve) => {
            const ids = new Set();
            this.on('get-all-tabs-response', (id) => {
                ids.add(id);
            })
            setTimeout(() => {
                resolve(ids)
            }, 250);
        })
        this.emit('get-all-tabs-request');
        return await response;
    }

    static install(Vue, options) {
        Vue.prototype.$CTCM = new CTCM(options);
    }

    get id() {
        return this.#id;
    }

    get leader() {
        return this.#leader;
    }

    /**
     * Socket.IO Style Emit Function
     * Emit event with arguments to other tabs
     * Only Accepts JSON-encodable arguments (so no Maps, Sets etc.)
     */
    emit() {
        const args = [...arguments];
        const event = args.shift();
        this.#channel.postMessage(JSON.stringify({
            event,
            args,
        }))
    }
}

export default CTCM;