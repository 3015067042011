function htmlDecode(input) {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
}

import currencySymbol from 'currency-symbol';
const numeral = require('numeral');

const currencySign = (symbol) => {
    let icon;
    let iconBefore = true;
    try {
        icon = htmlDecode(currencySymbol.symbol(symbol))
    }
    catch (error) {
        iconBefore = false;
        icon = symbol;
    }
    if ('undefined' == typeof(icon) || 'undefined' == icon || symbol == icon) {
        iconBefore = false;
        icon = symbol;
    }
    return {
        icon,
        before: iconBefore,
    }
}

class ModelManager {
    #instance;
    constructor(Vue) {
        this.#instance = new Vue({
            data: () => ({
                currencies: {},
                base: null,
            }),
            computed: {
                current() {
                    if (Vue.prototype.$auth && Vue.prototype.$auth.info.currency) {
                        return Vue.prototype.$auth.info.currency;
                    }
                    return this.base;
                },
                currentSign() {
                    const {icon} = currencySign(this.current);
                    return icon;
                }
            },
            methods: {
                async fetchUpdate() {
                    if (!Vue.prototype.$io.initialized) {
                        return;
                    }
                    try {
                        const data = await Vue.prototype.$io.request('App/Models/System', 'getCurrencies');
                        const {base, currencies} = data;
                        this.base = base;
                        this.currencies = currencies;
                        if (Vue.prototype.$CTCM) {
                            Vue.prototype.$CTCM.emit('currencies:update', {
                                base,
                                currencies,
                            })
                        }
                    }
                    catch (error) {
                        console.debug(error);
                    }
                },
                convert(value, from, to) {
                    value = parseFloat(value);
                    let ret = null;
                    const test = ([
                        (from == this.base || 'undefined' !== typeof this.currencies[from]),
                        (to == this.base || 'undefined' !== typeof this.currencies[to])
                    ].indexOf(false) == -1);
                    if (test && Number.isFinite(value)) {
                        if (from == to) {
                            ret = value;
                        }
                        else if (from == this.base) {
                            // converting from base
                            const rate = parseFloat(this.currencies[to]);
                            ret = (value * rate);
                        }
                        else if (to == this.base) {
                            // converting to base
                            const rate = parseFloat(this.currencies[from]);
                            ret = (value / rate);
                        }
                        else {
                            // converting "virtually" using base
                            const base = this.convert(value, this.base, from);
                            ret = this.convert(base, to, this.base);
                        }
                    }
                    return ret;
                },
                format(value, symbol) {
                    value = parseFloat(value);
                    if (!Number.isFinite(value)) {
                        return '∞';
                    }
                    const {icon, before} = currencySign(symbol);
                    const parts = [];
                    if (before) {
                        parts.push(icon);
                    }
                    const format = (before) ? '0,0.00' : '0,0.000000';
                    const n = numeral(value);
                    const num = n.format(format);
                    parts.push(num);
                    if (!before) {
                        parts.push(icon);
                    }
                    return parts.join(' ');
                },
                display(value) {
                    if (Vue.prototype.$auth) {
                        let display = Vue.prototype.$auth.info.currency;
                        if (!display) {
                            display = this.base;
                        }
                        const converted = this.convert(value, this.base, display);
                        return this.format(converted, display);
                    }
                    return this.format(value, this.base);
                },
                currencySign(symbol) {
                    return currencySign(symbol);
                }
            },
            created() {
                this.fetchUpdate();
                if (Vue.prototype.$CTCM) {
                    Vue.prototype.$CTCM.on('currencies:updated', ({base, currencies}) => {
                        this.base = base;
                        this.currencies = currencies;
                    })
                }
                if (Vue.prototype.$io) {
                    // On reconnection, make sure that everything is updated
                    Vue.prototype.$io.$on('connected', async () => {
                        this.fetchUpdate();
                    })
                    Vue.prototype.$io.$on('currencies:updated', async () => {
                        this.fetchUpdate();
                    });
                }
            },
        })
    }

    get instance() {
        return this.#instance;
    }

    static install (Vue, config) {
        const instance = new this(Vue, config);
        Vue.prototype.$currency = instance.instance;
    }
}

export default ModelManager;