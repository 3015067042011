import axios from 'axios';

class ModelManager {
    #instance;
    constructor(Vue) {
        this.#instance = axios.create({});
        this.#instance.interceptors.request.use((config) => {
            if (!config.headers) {
                config.headers = {};
            }
            config.baseURL = Vue.prototype.$config.host;
            if (Vue.prototype.$auth && 'string' == typeof Vue.prototype.$auth.jwt && Vue.prototype.$auth.jwt.trim().length > 0) {
                config.headers['Authorization'] = ['Bearer', Vue.prototype.$auth.jwt.trim()].join(' ');
            }
            return config;
        }, (error) => {
            return Promise.reject(error);
        })
    }

    get instance() {
        return this.#instance;
    }

    static install (Vue, config) {
        const instance = new this(Vue, config);
        Vue.prototype.$api = instance.instance;
    }
}

export default ModelManager;