<template>
    <v-progress-linear
        :style="style"
        :value="percentage"
        :height="__size"
        :color="color"
        dark
        v-if="0 !== goal"
        rounded
    >
        <div class="target-content-outer-wrapper w-100" :style="`height: ${this.__size}px;`">
            <div class="target-content-inner-wrapper w-100 h-100">
                <div :style="`height: ${this.__size}px;`" class="target-content-only justify-center text-center">
                    <fitty :options="fittyOpts" :key="makeFittyKey('current', 'single', display)">
                        <template v-slot:content>
                            <p class="mb-0" v-text="display"></p>
                        </template>
                    </fitty>
                </div>
            </div>
        </div>
    </v-progress-linear>
</template>

<script>
import md5 from 'md5';
export default {
    props: {
        goal: {
            type: Number,
            default: 0,
        },
        current: {
            type: Number,
            default: 0,
        },
        size: {
            type: [String, Number],
            default: 25,
        },
        vh: {
            type: [String, Number],
            default: 25,
        },
        goalIsMax: {
            type: Boolean,
            default: false,
        },
        goalIsLow: {
            type: Boolean,
            default: false,
        },
        monthProgress: {
            type: Number,
            default: 0,
        },
        formatter: {
            type: Function,
        },
        fittyOpts: {
            type: Object,
        },
        doubleSize: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        percentage() {
            if (this.goal == 0) {
                return null;
            }
            return ((this.current / this.goal) * 100)
        },
        display() {
            if (null == this.current) {
                return '∞';
            }
            return `${Math.round(this.percentage)}%`;
        },
        style() {
            return `position: relative; margin-top: 7.5px; border-radius: ${this.__size / 2}px;`;
        },
        displayStyle() {
            return `font-size: ${this.size * 0.32}px; position: relative; top: 1px;`;
        },
        displayClass() {
            return `text--${this.color}`;
        },
        color() {
            if (this.goalIsMax) {
                if (null == this.current) {
                    if (0 == this.goal) {
                        return '#0277BD';
                    }
                    return '#C62828';
                }
                if (0 == this.goal) {
                    return '#0277BD';
                }
                if (this.current < this.goal) {
                    return '#004D40';
                }
                else if (this.current > this.goal) {
                    return '#C62828';
                }
                else {
                    return '#0277BD';
                }
            }
            else {
                const rp = Math.round(this.percentage);
                const rmp = Math.round(this.monthProgress);
                if (0 == this.goal) {
                    return '#0277BD';
                }
                else if (rp < rmp) {
                    if (this.goalIsLow) {
                        return '#004D40';    
                    }
                    else {
                        return '#C62828';
                    }
                }
                else if (rp > rmp) {
                    if (this.goalIsLow) {
                        return '#C62828';
                    }
                    else {
                        return '#004D40';
                    }
                }
                else {
                    return '#0277BD';
                }
            }
        },
        __size() {
            let ret = this.size;
            if (this.vh) {
                ret = this.viewportHeight * (this.vh / 200)
            }
            if (this.doubleSize) {
                ret *= 2;
            }
            return ret;
        },
        text() {
            const parts = [this.current];
            if (this.goal > 0) {
                parts.push(this.goal);
            }
            const formatted = parts.map((v) => {
                if (null == v) {
                    return '∞';
                }
                if ('function' == typeof this.formatter) {
                    return this.formatter(v);
                }
                return v;
            })
            return formatted.join(' / ');
        },
        __current() {
            if (null == this.current) {
                return '∞';
            }
            if ('function' == typeof this.formatter) {
                return this.formatter(this.current);
            }
            return this.current;
        },
        __goal() {
            if (null == this.goal || 0 == this.goal) {
                return '∞';
            }
            if ('function' == typeof this.formatter) {
                return `of ${this.formatter(this.goal)}`;
            }
            return `of ${this.goal}`;
        },
        halfFittyOpts() {
            if (this.fittyOpts) {
                const ret = Object.assign({}, this.fittyOpts);
                ret.maxSize = this.fittyOpts.maxSize / 2;
                return ret;
            }
            return {};
        }
    },
    data: () => ({
        viewportHeight: window.innerHeight,
        u: 0,
    }),
    methods: {
        onResize() {
            this.viewportHeight = window.innerHeight
        },
        makeFittyKey(key, section, value) {
            return md5([key, section, value, this.u].join(':'));
        },
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
    },
    beforeDestroy() { 
        window.removeEventListener('resize', this.onResize); 
    },
    watch: {
        viewportHeight() {
            this.u ++;
        }
    }
}
</script>

<style lang="scss">
    .target-content-inner-wrapper {
        position: relative;

        .target-content-current,
        .target-content-goal {
            position: absolute;
            // background-color: rgba(0,0,0,0.55);
            // color: #FFF;
            display: flex;
            align-items: center;
            left: 5px;
            right: 5px;
        }

        .target-content-only {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            left: 5px;
            right: 5px;
        }

        .target-content-current {
            top: 0;
            justify-content: flex-end;
        }

        .target-content-goal {
            bottom: 0;
            justify-content: flex-end;
        }
    }
</style>