/**
 * Adds an instance of store2 (https://github.com/nbubna/store) to Vue for easy access from all components
 */

class Storage {
    static install (Vue) {
        Vue.prototype.$storage = require('store2');
    }
}

export default Storage;