<template>
    <v-card color="panel" elevation="11" :class="cardClass">
        <v-toolbar dense color="toolbar" tile flat class="flex-grow-0" v-if="!mobile">
            <v-toolbar-title v-text="board.text"></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon v-if="board.warm" color="deep-orange">mdi-fire</v-icon>
            <v-icon v-if="board.cold" color="cyan lighten-2">mdi-snowflake</v-icon>
            <v-btn icon v-if="showClose" @click="$emit('close')">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-system-bar color="toolbar" class="flex-grow-0" v-else>
            <span v-text="board.text"></span>
            <v-spacer></v-spacer>
            <v-icon v-if="board.warm" color="deep-orange">mdi-fire</v-icon>
            <v-icon v-if="board.cold" color="cyan lighten-2">mdi-snowflake</v-icon>
            <v-icon v-if="showClose" @click="$emit('close')">mdi-close</v-icon>
        </v-system-bar>
        <v-divider color="white"></v-divider>
        <v-card-text class="flex-grow-1 d-flex flex-column justify-stretch pa-3">
            <v-row :class="rowClass" v-if="fro.show.graph">
                <v-col cols="12">
                    <div class="board-bordered" style="min-height: 60px;">
                        <dials :bs="bs" :warm="board.warm" :cold="board.cold"></dials>
                    </div>
                </v-col>
            </v-row>
            <v-row :class="rowClass">
                <v-col cols="6" class="h-100">
                    <div class="board-bordered">
                        <label class="sb-cell-label">Spend</label>
                        <div class="sb-cell-content">
                            <span class="sb-cell-value sb-cell-value-day display-font day">
                                <fitty v-if="showFitty" :options="fittyOpts" :key="makeFittyKey('spend', 'day', formatCurrency(bs.stats.day.spend))">
                                    <template v-slot:content>
                                        <p class="mb-0" v-text="formatCurrency(bs.stats.day.spend)"></p>
                                    </template>
                                </fitty>
                            </span>
                        </div>
                        <v-divider></v-divider>
                        <div class="sb-cell-content">
                            <span class="sb-cell-value sb-cell-value-month display-font month">
                                <target
                                    :goal="bs.target.spend"
                                    :current="bs.stats.month.spend"
                                    :monthProgress="monthProgress"
                                    :vh="5"
                                    :showFitty="showFitty"
                                    :fittyOpts="fittyOpts"
                                    :doubleSize="doubleSize"
                                    :formatter="formatCurrency"
                                    :goalIsLow="true"
                                    >
                                </target>
                            </span>
                            <progressBar
                                :goal="bs.target.spend"
                                :current="bs.stats.month.spend"
                                :monthProgress="monthProgress"
                                :vh="5"
                                :showFitty="showFitty"
                                :fittyOpts="fittyOpts"
                                :doubleSize="doubleSize"
                                :formatter="formatCurrency"
                                :goalIsLow="true"
                                >
                            </progressBar>
                        </div>
                    </div>
                </v-col>
                <v-col cols="6" class="h-100">
                    <div class="board-bordered">
                        <label class="sb-cell-label">FTDs</label>
                        <div class="sb-cell-content">
                            <span class="sb-cell-value sb-cell-value-day display-font day">
                                <fitty v-if="showFitty" :options="fittyOpts" :key="makeFittyKey('ftds', 'day', formatInteger(bs.stats.day.ftds))">
                                    <template v-slot:content>
                                        <p class="mb-0" v-text="formatInteger(bs.stats.day.ftds)"></p>
                                    </template>
                                </fitty>
                            </span>
                        </div>
                        <v-divider></v-divider>
                        <div class="sb-cell-content">
                            <span class="sb-cell-value sb-cell-value-month display-font month">
                                <target
                                    :goal="bs.target.ftds"
                                    :current="bs.stats.month.ftds"
                                    :monthProgress="monthProgress"
                                    :vh="5"
                                    :showFitty="showFitty"
                                    :fittyOpts="fittyOpts"
                                    :doubleSize="doubleSize"
                                    :formatter="formatInteger"
                                    >
                                </target>
                            </span>
                            <progressBar
                                :goal="bs.target.ftds"
                                :current="bs.stats.month.ftds"
                                :monthProgress="monthProgress"
                                :vh="5"
                                :showFitty="showFitty"
                                :fittyOpts="fittyOpts"
                                :doubleSize="doubleSize"
                                :formatter="formatInteger"
                                >
                            </progressBar>
                        </div>
                    </div>
                </v-col>
            </v-row>
            <v-row :class="rowClass">
                <v-col cols="12" class="h-100">
                    <div class="board-bordered">
                        <label class="sb-cell-label">CPA</label>
                        <div class="sb-cell-content">
                            <span class="sb-cell-value sb-cell-value-day display-font day">
                                <fitty v-if="showFitty" :options="fittyOpts" :key="makeFittyKey('cpa', 'day', formatCurrency(bs.stats.day.cpa))">
                                    <template v-slot:content>
                                        <p class="mb-0" v-text="formatCurrency(bs.stats.day.cpa)"></p>
                                    </template>
                                </fitty>
                            </span>
                        </div>
                        <v-divider></v-divider>
                        <div class="sb-cell-content">
                            <span class="sb-cell-value sb-cell-value-month display-font month">
                                <target
                                    :goal="bs.target.cpa"
                                    :current="bs.stats.month.cpa"
                                    :monthProgress="monthProgress"
                                    :vh="5"
                                    :showFitty="showFitty"
                                    :fittyOpts="fittyOpts"
                                    :doubleSize="doubleSize"
                                    :goalIsMax="true"
                                    :formatter="formatCurrency"
                                    >
                                </target>
                            </span>
                            <progressBar
                                :goal="bs.target.cpa"
                                :current="bs.stats.month.cpa"
                                :monthProgress="monthProgress"
                                :vh="5"
                                :showFitty="showFitty"
                                :fittyOpts="fittyOpts"
                                :doubleSize="doubleSize"
                                :goalIsMax="true"
                                :formatter="formatCurrency"
                                >
                            </progressBar>
                        </div>
                    </div>
                </v-col>
            </v-row>
            <v-row class="flex-grow-1" v-if="board.warm">
                <v-col cols="6" class="h-100">
                    <div class="board-bordered">
                        <label class="sb-cell-label">Impressions</label>
                        <div class="sb-cell-content">
                            <span class="sb-cell-value sb-cell-value-day display-font day">
                                <fitty v-if="showFitty" :options="fittyOpts" :key="makeFittyKey('impressions', 'day', formatInteger(bs.stats.day.impressions))">
                                    <template v-slot:content>
                                        <p class="mb-0" v-text="formatInteger(bs.stats.day.impressions)"></p>
                                    </template>
                                </fitty>
                            </span>
                        </div>
                        <v-divider></v-divider>
                        <div class="sb-cell-content">
                            <span class="sb-cell-value sb-cell-value-month display-font month">
                                <fitty v-if="showFitty" :options="fittyOpts" :key="makeFittyKey('impressions', 'month', formatInteger(bs.stats.month.impressions))">
                                    <template v-slot:content>
                                        <p class="mb-0" v-text="formatInteger(bs.stats.month.impressions)"></p>
                                    </template>
                                </fitty>
                            </span>
                        </div>
                    </div>
                </v-col>
                <v-col cols="6" class="h-100">
                    <div class="board-bordered">
                        <label class="sb-cell-label">Clicks</label>
                        <div class="sb-cell-content">
                            <span class="sb-cell-value sb-cell-value-day display-font day">
                                <fitty v-if="showFitty" :options="fittyOpts" :key="makeFittyKey('clicks', 'day', formatInteger(bs.stats.day.clicks))">
                                    <template v-slot:content>
                                        <p class="mb-0" v-text="formatInteger(bs.stats.day.clicks)"></p>
                                    </template>
                                </fitty>
                            </span>
                        </div>
                        <v-divider></v-divider>
                        <div class="sb-cell-content">
                            <span class="sb-cell-value sb-cell-value-month display-font month">
                                <fitty v-if="showFitty" :options="fittyOpts" :key="makeFittyKey('clicks', 'month', formatInteger(bs.stats.month.clicks))">
                                    <template v-slot:content>
                                        <p class="mb-0" v-text="formatInteger(bs.stats.month.clicks)"></p>
                                    </template>
                                </fitty>
                            </span>
                        </div>
                    </div>
                </v-col>
            </v-row>
            <v-row class="flex-grow-1" v-if="board.warm">
                <v-col cols="6" class="h-100">
                    <div class="board-bordered">
                        <label class="sb-cell-label">Leads</label>
                        <div class="sb-cell-content">
                            <span class="sb-cell-value sb-cell-value-day display-font day">
                                <fitty v-if="showFitty" :options="fittyOpts" :key="makeFittyKey('leads', 'day', formatInteger(bs.stats.day.leads))">
                                    <template v-slot:content>
                                        <p class="mb-0" v-text="formatInteger(bs.stats.day.leads)"></p>
                                    </template>
                                </fitty>
                            </span>
                        </div>
                        <v-divider></v-divider>
                        <div class="sb-cell-content">
                            <span class="sb-cell-value sb-cell-value-month display-font month">
                                <fitty v-if="showFitty" :options="fittyOpts" :key="makeFittyKey('leads', 'month', formatInteger(bs.stats.month.leads))">
                                    <template v-slot:content>
                                        <p class="mb-0" v-text="formatInteger(bs.stats.month.leads)"></p>
                                    </template>
                                </fitty>
                            </span>
                        </div>
                    </div>
                </v-col>
                <v-col cols="6" class="h-100">
                    <div class="board-bordered">
                        <label class="sb-cell-label">CPL</label>
                        <div class="sb-cell-content">
                            <span class="sb-cell-value sb-cell-value-day display-font day">
                                <fitty v-if="showFitty" :options="fittyOpts" :key="makeFittyKey('cpl', 'day', formatCurrency(bs.stats.day.cpl))">
                                    <template v-slot:content>
                                        <p class="mb-0" v-text="formatCurrency(bs.stats.day.cpl)"></p>
                                    </template>
                                </fitty>
                            </span>
                        </div>
                        <v-divider></v-divider>
                        <div class="sb-cell-content">
                            <span class="sb-cell-value sb-cell-value-month display-font month">
                                <fitty v-if="showFitty" :options="fittyOpts" :key="makeFittyKey('cpl', 'month', formatCurrency(bs.stats.month.cpl))">
                                    <template v-slot:content>
                                        <p class="mb-0" v-text="formatCurrency(bs.stats.month.cpl)"></p>
                                    </template>
                                </fitty>
                            </span>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
// import activity from '../widgets/activity';
// import activityTarget from '../widgets/activityTarget';
import dials from '../widgets/dials';
import target from '../widgets/monthlyAndTarget';
import progressBar from '../widgets/targetProgress';
import numeral from 'numeral';
import md5 from 'md5';

export default {
    components: {
        // activity,
        target,
        // activityTarget,
        progressBar,
        dials,
    },
    props: {
        board: {
            type: Object,
            required: true,
        },
        stats: {
            type: Object,
            required: true,
        },
        moment: {},
        monthProgress: {},
        doubleSize: {
            type: Boolean,
            default: false,
        },
        mobile: {
            type: Boolean,
            default: false,
        },
        showClose: {
            type: Boolean,
            default: false,
        },
        showing: {
            type: Boolean,
            default: true,
        },
    },
    data: () => ({
        viewportHeight: window.innerHeight,
        showFitty: false,
        timeouts: {},
    }),
    computed: {
        bs() {
            if ('undefined' !== typeof this.stats[this.board.value]) {
                return this.stats[this.board.value];
            }
            else {
                return {
                    stats: {
                        day: {
                            spend: 0,
                            cpi: null,
                            cpc: null,
                            cpl: null,
                            cpa: null,
                            impressions: 0,
                            ctr: null,
                            itl: null,
                            ita: null,
                            clicks: 0,
                            ctl: null,
                            cta: null,
                            leads: 0,
                            cr: null,
                            ftds: 0,
                        },
                        month: {
                            spend: 0,
                            cpi: null,
                            cpc: null,
                            cpl: null,
                            cpa: null,
                            impressions: 0,
                            ctr: null,
                            itl: null,
                            ita: null,
                            clicks: 0,
                            ctl: null,
                            cta: null,
                            leads: 0,
                            cr: null,
                            ftds: 0,
                        }
                    },
                    target: {
                        cpa: 0,
                        ftds: 0,
                        spend: 0,
                    },
                }
            }
        },
        hasGoal() {
            let ret = false;
            if (this.bs && this.bs.target) {
                for (let key in this.bs.target) {
                    if (null !== this.bs.target[key] && 0 !== this.bs.target[key]) {
                        ret = true;
                    }
                }
            }
            return ret;
        },
        showActivityCell() {
            return (this.board.warm || this.hasGoal);
        },
        firstRowCellWidth() {
            let cols = 1;
            if (this.showActivityCell) {
                cols ++;
            }
            if (this.board.external) {
                cols += 2;
            }
            return Math.ceil(12 / cols);
        },
        rowClass() {
            return (!this.board.warm) ? 'h-100' : 'flex-grow-1';
        },
        fittyOpts() {
            const ret = {
                maxSize: (this.viewportHeight * 0.03),
            }
            if (this.doubleSize) {
                ret.maxSize *= 2;
            }
            return ret;
        },
        cardClass() {
            const ret = [
                'h-100',
                'd-flex',
                'flex-column',
                'justify-stretch',
                'sb-board',
                'desktop',
                'mx-3'
            ]
            if (this.doubleSize) {
                ret.push('desktop-double');
            }
            return ret.join(' ');
        },
        fro() {
            const ret =  {
                sizes: {
                    graph: {
                        xs: 12,
                        md: 6,
                        lg: 4,
                    },
                    info: {
                        xs: 12,
                        md: 6,
                        lg: 8,
                    }
                },
                show: {
                    graph: true,
                }
            }
            if (!this.board.warm) {
                ret.show.graph = false;
                ret.sizes.info.md = 12;
                ret.sizes.info.lg = 12;
            }
            return ret
        }
    },
    methods: {
        makeFittyKey(key, section, value) {
            return md5([key, section, value].join(':'));
        },
        formatCurrency(value) {
            if (null == value) {
                return '∞';
            }
            return this.$currency.display(value);
        },
        formatInteger(value) {
            if (null == value) {
                return '∞';
            }
            const n = numeral(value)
            return n.format('0,0');
        },
        formatPercent(value) {
            if (null == value) {
                return '∞';
            }
            const n = numeral(value)
            return `${n.format('0,0.00')}%`;
        },
        onResize() {
            this.viewportHeight = window.innerHeight
        }
    },
    mounted() {
        this.showFitty = this.showing;
        this.$watch('showing', (nv) => {
            clearTimeout(this.timeouts.showFitty);
            if (false == nv) {
                this.showFitty = nv;
            }
            else {
                this.timeouts.showFitty = setTimeout(() => {
                    this.showFitty = nv;
                }, 300);
            }
        })
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
    },
    beforeDestroy() { 
        window.removeEventListener('resize', this.onResize); 
        for (let i in this.timeouts) {
            clearTimeout(this.timeouts[i]);
        }
    },
}
</script>

<style lang="scss">
    .v-application {
        .board-bordered {
            border: solid 1px var(--v-toolbar-base);
            width: 100%;
            height: 100%;

            .sb-cell-label {
                display: block;
                padding: 5px 12px;
                font-size: 1.2rem;
                line-height: 1.5rem;
                font-weight: bold;
            }

            .sb-cell-content {
                padding: 5px 12px;
                position: relative;
            }
        }

        &.theme--dark {
            .board-bordered {
                border: solid 1px #ffffff;
            }
        }
    }
</style>