var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":true,"max-width":"400","persistent":"","overlay-color":"background"}},[_c('validation-observer',{ref:"observer",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',{attrs:{"color":"panel"}},[_c('v-toolbar',{staticClass:"remove-end-padding",attrs:{"color":"toolbar","dense":"","flat":""}},[_c('v-toolbar-title',[_vm._v("My Account")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-divider',{attrs:{"color":"white"}}),_c('v-card-text',{staticClass:"pt-3"},[_c('validation-provider',{attrs:{"name":"Email Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"email","loading":_vm.loading,"disabled":_vm.loading,"error-messages":errors,"label":"Email Address","required":"","clearable":!_vm.loading,"cb":"login"},on:{"keydown":_vm.cbIfEnter},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Display Currency","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.currencies,"loading":_vm.loading,"disabled":_vm.loading,"error-messages":errors,"label":"Display Currency","required":"","clearable":!_vm.loading},model:{value:(_vm.form.currency),callback:function ($$v) {_vm.$set(_vm.form, "currency", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.currency"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Timezone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.timezones,"loading":_vm.loading,"disabled":_vm.loading,"error-messages":errors,"label":"Timezone","required":"","clearable":!_vm.loading},model:{value:(_vm.form.timezone),callback:function ($$v) {_vm.$set(_vm.form, "timezone", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.timezone"}})]}}],null,true)})],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"primary","text":"","disabled":invalid || !_vm.$io.connected,"loading":_vm.loading},on:{"click":_vm.login}},[_c('span',[_vm._v("Save")]),_c('v-icon',{class:_vm.$vuetify.rtl ? 'mr-2' : 'ml-2',domProps:{"textContent":_vm._s(_vm.$vuetify.rtl ? 'mdi-chevron-left' : 'mdi-chevron-right')}})],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }