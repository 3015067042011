import { sounds } from '../assets';
import ion from '../libraries/ion';

const SoundsPlugin = {
    install(Vue) {
        Vue.prototype.$sounds = new Vue({
            data:() => ({
                loaded: false,
                interacted: false,
            }),
            computed: {
                list() {
                    const ret = [];
                    for (let key in sounds) {
                        const text = key.substr(6, key.length - 9);
                        ret.push({
                            value: key,
                            text,
                        })
                    }
                    return ret;
                }
            },
            methods: {
                play(name) {
                    if ('undefined' !== typeof(sounds[name])) {
                        ion.sound.play(name);
                    }
                },
            },
            created() {
                const posted = new Set();
                const sl = [];
                let relpath;
                for (let name in sounds) {
                    const path = sounds[name];
                    const li = path.lastIndexOf('/');
                    const p = path.slice(1, li);
                    if (!relpath) {
                        relpath = p;
                        relpath += '/'
                    }
                    const mn = path.slice(li + 1).replace(/\.(mp3|aac)$/g, '');
                    if (mn && !posted.has(mn)) {
                        const obj = {
                            name: mn,
                            alias: name,
                        }
                        sl.push(obj);
                        posted.add(mn);
                    }
                }
                ion.sound({
                    sounds: sl,
                    volume: 1,
                    preload: true,
                    path: '/' + relpath,
                    multiplay: true,
                    ready_callback: () => {
                        this.loaded = true;
                    }
                })
            },
        });
    }
}

export default SoundsPlugin;