<template>
    <v-dialog :value="true" max-width="400" persistent overlay-color="background">
        <validation-observer slim ref="observer" v-slot="{ invalid }">
            <v-card color="panel">
                <v-toolbar color="toolbar" dense flat class="remove-end-padding">
                    <v-toolbar-title>My Account</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn text @click="$emit('close')">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-divider color="white"></v-divider>
                <v-card-text class="pt-3">
                    <validation-provider v-slot="{ errors }" name="Email Address" rules="required">
                        <v-text-field type="email" v-model.trim="form.email" :loading="loading" :disabled="loading" :error-messages="errors" label="Email Address" required :clearable="!loading" cb="login" @keydown="cbIfEnter"></v-text-field>
                    </validation-provider>
                    <validation-provider v-slot="{ errors }" name="Display Currency" rules="required">
                        <v-autocomplete v-model.trim="form.currency" :items="currencies" :loading="loading" :disabled="loading" :error-messages="errors" label="Display Currency" required :clearable="!loading"></v-autocomplete>
                    </validation-provider>
                    <validation-provider v-slot="{ errors }" name="Timezone" rules="required">
                        <v-autocomplete v-model.trim="form.timezone" :items="timezones" :loading="loading" :disabled="loading" :error-messages="errors" label="Timezone" required :clearable="!loading"></v-autocomplete>
                    </validation-provider>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn color="primary" text :disabled="invalid || !$io.connected" :loading="loading" @click="login">
                    <span>Save</span>
                    <v-icon :class="$vuetify.rtl ? 'mr-2' : 'ml-2'" v-text="$vuetify.rtl ? 'mdi-chevron-left' : 'mdi-chevron-right'"></v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </validation-observer>
    </v-dialog>
</template>

<script>
// import momentTimezone from 'moment-timezone';
import ct from 'countries-and-timezones';
export default {
  data: () => ({
    form: {
        email: '',
        timezone: '',
        currency: '',
    },
    loading: false,
  }),
  computed: {
      currencies() {
          const ret = new Set();
          if (null !== this.$currency.base) {
            ret.add({
                text: this.$currency.base,
                value: this.$currency.base,
            })
          }
          const others = Object.keys(this.$currency.currencies);
          for (let i = 0; i < others.length; i++) {
              const symbol = others[i];
              ret.add({
                text: symbol,
                value: symbol,
              })
          }
          return [...ret];
      },
      timezones() {
          const ret = new Set();
          ret.add({
              text: 'UTC',
              value: 'UTC',
          })
          const zones = ct.getAllTimezones();
          const zs = Object.keys(zones).filter((v) => {
              const zone = zones[v];
              return ('string' !== typeof zone.aliasOf || 0 == zone.aliasOf.trim().length);
          });
          const byHeader = {};
          zs.map((v) => {
            const header = (v.replace(/_/g, ' ').indexOf('/') > -1) ? v.replace(/_/g, ' ').split('/')[0] : 'Other';
            if ('undefined' == typeof byHeader[header]) {
                byHeader[header] = new Set();
            }
            byHeader[header].add({
                value: v,
                text: v.replace(/_/g, ' '),
            })
          })
          for (let header in byHeader) {
              ret.add({
                  header,
              })
              byHeader[header].forEach((v) => {
                  ret.add(v);
              })
          }
          return [...ret];
      }
  },
  methods: {
      async login() {
        const valid = await this.$refs.observer.validate();
        if (!valid) {
            console.warn(`Got Errors:`, this.$refs.observer.errors);
            return false;
        }
        this.loading = true;
        try {
            await this.$io.request('App/Models/User', 'updatePersonalDetails', this.form);
        }
        catch (error) {
            this.onIoRequestError(error, true);
        }
        this.loading = false;
        this.$emit('close');
      },
      loadDefaults() {
          this.form.email = this.$auth.info.email;
          this.form.timezone = this.$auth.info.timezone;
          this.form.currency = this.$auth.info.currency;
      }
  },
  mounted() {
      this.$auth.$on('me:updated', this.loadDefaults)
      this.loadDefaults();
  },
  beforeDestroy() {
      this.$auth.$off('me:updated', this.loadDefaults)
  },
};
</script>
