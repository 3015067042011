import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import board from '../views/scoreboard/board.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: board
  },
  // {
  //   path: '/scoreboard/old',
  //   name: 'OldHome',
  //   component: Home
  // },
  {
    path: '/admin/',
    name: 'admin/list',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/list.vue'),
    meta: {
      permissions: ['admin:list']
    }
  },
  {
    path: '/admin/users',
    name: 'admin/users/list',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/users/list.vue'),
    meta: {
      permissions: ['users:list'],
      list: {
        model: 'App/Models/User',
        modelName: 'Users',
        addTo: {
          name: 'admin/users/add'
        },
        viewTo: {
          name: 'admin/users/view'
        }
      }
    }
  },
  {
    path: '/admin/users/add',
    name: 'admin/users/add',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/users/edit.vue'),
    meta: {
      permissions: ['users:create'],
      edit: {
        add: true,
        model: 'App/Models/User',
        modelName: 'User',
        addTo: {
          name: 'admin/users/add'
        },
        viewTo: {
          name: 'admin/users/view'
        }
      }
    }
  },
  {
    path: '/admin/users/:id',
    name: 'admin/users/view',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/users/edit.vue'),
    meta: {
      permissions: ['users:read'],
      edit: {
        add: false,
        model: 'App/Models/User',
        modelName: 'User',
        addTo: {
          name: 'admin/users/add'
        },
        viewTo: {
          name: 'admin/users/view'
        }
      }
    }
  },
  {
    path: '/admin/groups',
    name: 'admin/groups/list',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/groups/list.vue'),
    meta: {
      permissions: ['groups:list'],
      list: {
        model: 'App/Models/Group',
        modelName: 'Groups',
        addTo: {
          name: 'admin/groups/add'
        },
        viewTo: {
          name: 'admin/groups/view'
        }
      }
    }
  },
  {
    path: '/admin/groups/add',
    name: 'admin/groups/add',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/groups/edit.vue'),
    meta: {
      permissions: ['groups:create'],
      edit: {
        add: true,
        model: 'App/Models/Group',
        modelName: 'Group',
        addTo: {
          name: 'admin/groups/add'
        },
        viewTo: {
          name: 'admin/groups/view'
        }
      }
    }
  },
  {
    path: '/admin/groups/:id',
    name: 'admin/groups/view',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/groups/edit.vue'),
    meta: {
      permissions: ['groups:read'],
      edit: {
        add: false,
        model: 'App/Models/Group',
        modelName: 'Group',
        addTo: {
          name: 'admin/groups/add'
        },
        viewTo: {
          name: 'admin/groups/view'
        }
      }
    }
  },
  {
    path: '/admin/targets',
    name: 'admin/targets/list',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/targets/list.vue'),
    meta: {
      permissions: ['targets:list'],
      list: {
        model: 'App/Models/Target',
        modelName: 'Targets',
        addTo: {
          name: 'admin/targets/add'
        },
        viewTo: {
          name: 'admin/targets/view'
        }
      }
    }
  },
  {
    path: '/admin/targets/add',
    name: 'admin/targets/add',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/targets/edit.vue'),
    meta: {
      permissions: ['targets:create'],
      edit: {
        add: true,
        model: 'App/Models/Target',
        modelName: 'Target',
        addTo: {
          name: 'admin/targets/add'
        },
        viewTo: {
          name: 'admin/targets/view'
        }
      }
    }
  },
  {
    path: '/admin/targets/:id',
    name: 'admin/targets/view',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/targets/edit.vue'),
    meta: {
      permissions: ['targets:read'],
      edit: {
        add: false,
        model: 'App/Models/Target',
        modelName: 'Target',
        addTo: {
          name: 'admin/targets/add'
        },
        viewTo: {
          name: 'admin/targets/view'
        }
      }
    }
  },
  {
    path: '/admin/filters',
    name: 'admin/filters/list',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/filters/list.vue'),
    meta: {
      permissions: ['filters:list'],
      list: {
        model: 'App/Models/Filter',
        modelName: 'Filters',
        addTo: {
          name: 'admin/filters/add'
        },
        viewTo: {
          name: 'admin/filters/view'
        }
      }
    }
  },
  {
    path: '/admin/filters/add',
    name: 'admin/filters/add',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/filters/edit.vue'),
    meta: {
      permissions: ['filters:create'],
      edit: {
        add: true,
        model: 'App/Models/Filter',
        modelName: 'Filter',
        addTo: {
          name: 'admin/filters/add'
        },
        viewTo: {
          name: 'admin/filters/view'
        }
      }
    }
  },
  {
    path: '/admin/filters/:id',
    name: 'admin/filters/view',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/filters/edit.vue'),
    meta: {
      permissions: ['filters:read'],
      edit: {
        add: false,
        model: 'App/Models/Filter',
        modelName: 'Filter',
        addTo: {
          name: 'admin/filters/add'
        },
        viewTo: {
          name: 'admin/filters/view'
        }
      }
    }
  },
  {
    path: '/admin/chatbots',
    name: 'admin/chatbots/list',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/chatbots/list.vue'),
    meta: {
      permissions: ['chatbots:list'],
      list: {
        model: 'App/Models/Chatbot',
        modelName: 'Chatbots',
        addTo: {
          name: 'admin/chatbots/add'
        },
        viewTo: {
          name: 'admin/chatbots/view'
        }
      }
    }
  },
  {
    path: '/admin/chatbots/add',
    name: 'admin/chatbots/add',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/chatbots/edit.vue'),
    meta: {
      permissions: ['chatbots:create'],
      edit: {
        add: true,
        model: 'App/Models/Chatbot',
        modelName: 'Chatbot',
        addTo: {
          name: 'admin/chatbots/add'
        },
        viewTo: {
          name: 'admin/chatbots/view'
        }
      }
    }
  },
  {
    path: '/admin/chatbots/messages',
    name: 'admin/chatbots/messages',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/chatbots/messages.vue'),
    meta: {
      permissions: ['chatbots:read'],
    }
  },
  {
    path: '/admin/chatbots/:id',
    name: 'admin/chatbots/view',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/chatbots/edit.vue'),
    meta: {
      permissions: ['chatbots:read'],
      edit: {
        add: false,
        model: 'App/Models/Chatbot',
        modelName: 'Chatbot',
        addTo: {
          name: 'admin/chatbots/add'
        },
        viewTo: {
          name: 'admin/chatbots/view'
        }
      }
    }
  },

  {
    path: '/admin/debug',
    name: 'admin/debug/index',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/debug/index.vue'),
    meta: {
      permissions: ['admin:debug'],
    }
  },

  {
    path: '/reports/',
    name: 'reports/list',
    component: () => import(/* webpackChunkName: "reports" */ '../views/reports/list.vue'),
    meta: {
      permissions: ['reports:list']
    }
  },
  {
    path: '/reports/marketing',
    name: 'reports/marketing',
    component: () => import(/* webpackChunkName: "reports" */ '../views/reports/marketing.vue'),
    meta: {
      permissions: ['reports:marketing']
    }
  },
  {
    path: '/my/chatbots',
    name: 'my/chatbots',
    component: () => import(/* webpackChunkName: "me" */ '../views/my/chatbots.vue'),
    meta: {
      permissions: ['me:chatbots'],
      list: {
        model: 'App/Models/UserChatbot',
        modelName: 'Chatbots',
        addTo: null,
        viewTo: null,
      }
    }
  },
  {
    path: '/campaigns/live',
    name: 'campaigns/live',
    component: () => import(/* webpackChunkName: "me" */ '../views/campaigns/live.vue'),
    meta: {
      permissions: ['campaigns:live'],
    }
  },
  {
    path: '/dialer/summary',
    name: 'dialer/summary',
    component: () => import(/* webpackChunkName: "me" */ '../views/dialer/summary.vue'),
    meta: {
      permissions: ['dialer:summary'],
    }
  },
  {
    path: '*',
    name: '404',
    component: board
  }
]

const router = new VueRouter({
  routes,
  mode: (window.location.href.includes('minea.io')) ? 'hash' : 'history',
  scrollBehavior() {
    return window.scroll({ top: 0 });
  },
  base: process.env.BASE_URL,
})

export default router
