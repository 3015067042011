<template>
    <v-simple-table v-bind="bind">
        <template v-slot:default>
            <thead>
                <tr v-if="autoable">
                    <th :class="headerCellClass('px-0')">
                        <div class="d-flex w-100 justify-center align-center">
                            <v-checkbox class="mt-0 mb-1" hide-details readonly @click="handleChooseAllClick" :input-value="autoable && true === val"></v-checkbox>
                        </div>
                    </th>
                    <th :class="headerCellClass()">
                        <span class="info--text">Choose All</span>
                        <a class="info--text mx-3" @click="handleChooseAllInfoClick">
                            <v-icon color="info" small>mdi-information</v-icon>
                        </a>
                    </th>
                </tr>
                <tr>
                    <th style="width: 60px;" :class="headerCellClass('px-0')">
                        <div class="d-flex w-100 justify-center align-center">
                            <v-checkbox class="mt-0 mb-1" hide-details v-bind="selectAllBind" readonly @click="handleSelectAllClick"></v-checkbox>
                        </div>
                    </th>
                    <th :class="headerCellClass()"><span v-text="label"></span></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, i) in _items" :key="i">
                    <td class="px-0">
                        <div class="d-flex w-100 justify-center align-center">
                            <v-switch v-if="!isAutoAll" class="mt-0 mb-1" v-model="val" :value="item.value" hide-details multiple></v-switch>
                            <v-checkbox v-else class="mt-0 mb-1" hide-details :input-value="true" readonly disabled></v-checkbox>
                        </div>
                    </td>
                    <td><span v-text="item.text"></span></td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
import merge from 'lodash.merge';
export default {
    props: {
        value: {
            type: [Array, Boolean],
            default: () => {
                return [];
            }
        },
        items: {
            type: Array,
            default: () => {
                return [];
            }
        },
        label: {
            type: String,
            default: 'Item',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        autoable: {
            type: Boolean,
            default: false,
        },
        filter: {
            type: Function,
        }
    },
    computed: {
        bind() {
            const ret = merge({}, this.$props, this.$attrs);
            delete ret.value;
            delete ret.items;
            ret.dense = true;
            return ret;
        },
        selectAllBind() {
            const ret = {
                indeterminate: false,
                'input-value': false,
            }
            if (this.loading) {
                ret['input-value'] = false;
            }
            else if (this.autoable && this.val === true) {
                ret['input-value'] = true;
                ret.disabled = true;
            }
            else if (this.val.length == this._items.length) {
                ret['input-value'] = true;
            }
            else if (this.val.length > 0) {
                ret.indeterminate = true;
            }
            return ret;
        },
        isAutoAll() {
            return (this.autoable && true === this.val && !Array.isArray(this.val))
        },
        _items() {
            if ('function' == typeof this.filter) {
                return this.items.filter((v) => {
                    return this.filter.apply(null, [v, this.items, this.val]);
                })    
            }
            return this.items;
        }
    },
    methods: {
        handleSelectAllClick() {
            if (this.val.length > 0) {
                while (this.val.length > 0) {
                    this.val.splice(0, 1);
                }
            }
            else {
                for (let i = 0; i < this.items.length; i++) {
                    const item = this.items[i];
                    this.val.push(item.value);
                }
            }
        },
        headerCellClass(additional) {
            const ignore = [
                'v-data-table',
                'v-data-table--dense',
                'v-data-table--fixed-header',
                'theme--dark',
                'theme--light',
            ];
            const ret = new Set();
            if (this.$el && this.$el.classList) {
                [...this.$el.classList].filter((v) => {
                    return (ignore.indexOf(v) == -1);
                }).map((v) => {
                    ret.add(v);
                })
            }
            if (Array.isArray(additional)) {
                for (let i = 0; i < additional.length; i++) {
                    const add = additional[i];
                    ret.add(add);
                }
            }
            else if ('string' == typeof additional) {
                const parts = additional.split(' ');
                for (let i = 0; i < parts.length; i++) {
                    const part = parts[i];
                    ret.add(part);
                }
            }
            return [...ret].filter((v) => {
                return ('string' == typeof v);
            }).map((v) => {
                return v.trim();
            }).join(' ');
        },
        handleChooseAllClick() {
            if ('boolean' == typeof this.val) {
                this.val = [];
            }
            else {
                this.val = true;
            }
        },
        handleChooseAllInfoClick() {
            this.$Swal.fire({
                icon: 'info',
                title: 'About the Choose All Option',
                text: 'When this option is chosen, options will not need to be chosen / selected each time a new item is added to the database. All available options are automatically selected.'
            })
        }
    },
    data: () => ({
        val: [],
    }),
    mounted() {
        if (Array.isArray(this.value)) {
            for (let i = 0; i < this.value.length; i++) {
                const v = this.value[i];
                this.val.push(v);
            }
        }
        else {
            this.val = this.value;
        }
        this.$watch('value', (nv) => {
            if (this.val === nv) {
                return;
            }
            this.val = nv;
        })
        this.$watch('val', () => {
            this.$emit('input', this.val);
            this.$emit('change', this.val);
            this.$emit('update', this.val);
        })
    },
}
</script>