import { alert, success, info, error, notice, defaultModules } from '@pnotify/core/dist/PNotify.js';
import * as PNotifyMobile from '@pnotify/mobile/dist/PNotifyMobile.js';
defaultModules.set(PNotifyMobile, {});
import '@pnotify/core/dist/PNotify.css';
import '@pnotify/mobile/dist/PNotifyMobile.css';
import '@pnotify/core/dist/Material.css';
import 'material-design-icons/iconfont/material-icons.css';
import { defaults } from '@pnotify/core';
defaults.styling = 'material';
defaults.icons = 'material';
defaults.stack.modal = false;
defaults.stack.maxOpen = 1;
defaults.stack.maxStrategy = 'close';
defaults.stack.maxClosureCausesWait = false;

const PNotify = {
    alert: function() {
        const n = alert.apply(PNotifyMobile, [...arguments]);
        n.on('click', () => {
            n.close();
        })
        return n;
    },
    success: function() {
        const n = success.apply(PNotifyMobile, [...arguments]);
        n.on('click', () => {
            n.close();
        })
        return n;
    },
    info: function() {
        const n = info.apply(PNotifyMobile, [...arguments]);
        n.on('click', () => {
            n.close();
        })
        return n;
    },
    error: function() {
        const n = error.apply(PNotifyMobile, [...arguments]);
        n.on('click', () => {
            n.close();
        })
        return n;
    },
    notice: function() {
        const n = notice.apply(PNotifyMobile, [...arguments]);
        n.on('click', () => {
            n.close();
        })
        return n;
    },
}

class VPNotify {
    static install (Vue) {
        Vue.prototype.$PNotify = PNotify;
    }
}

export default VPNotify;