import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.css';
import '../vuetify.scss';
Vuetify.config.silent = true;

Vue.use(Vuetify);

const dot = require('dot-object');
import { Ripple } from 'vuetify/lib/directives'
import numeral from 'numeral';

const mixin = {
    methods: {
        cbIfEnter(e) {
            if (e.keyCode == 13) {
                const cb = e.target.getAttribute('cb');
                if ('function' == typeof(this[cb])) {
                    this[cb]();
                }
            }
        },
        getItemPart(value, items, part, key = 'value') {
            items = JSON.parse(JSON.stringify(items));
            let item;
            if (Array.isArray(items)) {
                const filtered = items.filter((v) => {
                    return (dot.pick(key, v) == value);
                });
                const mapped = filtered.map((v) => {
                    if (part) {
                        return dot.pick(part, v);
                    }
                    return v;
                })
                item = mapped[0];
            }
            return item;
        },
        async _getResultFromListener(event, evt, payload) {
            const promise = new Promise((resolve, reject) => {
                this.$emit(event, evt, {payload, resolve, reject});
            });
            return await promise;
        },
        goHome() {
            this.$router.push('/');
        },
        onAxiosError(error, important) {
            let title = '';
            let text = '';
            if (error.response) {
                if (error.response.status == 404) {
                    title = 'System Error';
                    text = 'Endpoint Not Found';
                }
                else if ('object' == typeof(error.response.data)) {
                    title = 'Error';
                    if ('string' == typeof(error.response.data.data)) {
                        text = error.response.data.data;
                    }
                    else if (Array.isArray(error.response.data.errors) && error.response.data.errors.length > 0) {
                        const err = error.response.data.errors[0];
                        if ('string' == typeof(err)) {
                            text = err;
                        }
                        else if ('object' == typeof err && null !== err && 'string' == typeof err.message) {
                            text = err.message;
                        }
                    }
                }
                else {
                    title = 'Request Error';
                    text = error.response.statusText;
                }
            }
            else {
                title = 'System Error';
                text = error.toString();
            }
            if (important) {
                Vue.prototype.$Swal.fire({
                    title,
                    text,
                    icon: 'error',
                    focusConfirm: true,
                })
            }
            else {
                Vue.prototype.$PNotify.error({
                    title,
                    text
                })
            }
        },
        onIoRequestError(error, important = false) {
            const title = 'Error';
            const text = error;
            if (important) {
                Vue.prototype.$Swal.fire({
                    title,
                    text,
                    icon: 'error',
                    focusConfirm: true,
                })
            }
            else {
                Vue.prototype.$PNotify.error({
                    title,
                    text
                })
            }
        },
        formatInteger(value) {
            if (null == value) {
                return '∞';
            }
            const n = numeral(value)
            return n.format('0,0');
        },
        formatPercent(value) {
            if (null == value) {
                return '∞';
            }
            const n = numeral(value)
            return `${n.format('0,0.00')}%`;
        },
        formatCurrency(value) {
            if (null == value) {
                return '∞';
            }
            return this.$currency.display(value);
        },
    },
    watch: {
        '$vuetify.rtl': function() {
            if ('undefined' == typeof(this.$parent)) {
                const el = document.body;
                if (this.$vuetify.rtl) {
                    el.classList.add('body-rtl');
                }
                else {
                    el.classList.remove('body-rtl');
                }
            }
        }
    },
}

Vue.mixin(mixin);

const config = {
    icons: {
        iconfont: 'mdiSvg',
    },
    theme: {
        options: {
            customProperties: true,
        },
        dark: false,
        themes: {
            dark: {
                primary: '#2196F3',
                secondary: '#D50000',
                background: '#690808',
                panel: '#1d1d1f',
                toolbar: '#1d1d1f',
            },
            light: {
                primary: '#2196F3',
                secondary: '#C62828',
                background: '#f5f5f5',
                panel: '#fbfbfd',
                toolbar: '#1d1d1f',
            },
        },
    },
    directives: {
        Ripple
    }
};

export default new Vuetify(config);
