<template>
    <v-carousel v-model="carousel" :cycle="true" :show-arrows="false" :touchless="true" :hide-delimiters="1 == slides.length" :hide-delimiter-background="1 == slides.length" :interval="cycle" class="h-100 mt-2 sb-carousel" style="height: 100% !important;">
        <v-carousel-item v-for="(slide, i) in slides" :key="i" style="height: 100% !important;">
            <v-row class="h-100">
                <v-col cols="12" :style="cellHeightStyle" :md="cellWidth" v-for="(board, si) in slide" :key="[board.value, si].join('-')">
                    <verticalBoard :board="board" :stats="stats" :moment="moment" :monthProgress="monthProgress" :mobile="false" v-if="vertical"></verticalBoard>
                    <desktopBoard :board="board" :stats="stats" :moment="moment" :monthProgress="monthProgress" v-else :doubleSize="doubleSize"></desktopBoard>
                </v-col>
            </v-row>
        </v-carousel-item>
    </v-carousel>
</template>

<script>
import chunk from 'lodash.chunk';

import desktopBoard from '../../../components/scoreboard/slideLayouts/desktop';
import verticalBoard from '../../../components/scoreboard/slideLayouts/vertical';

export default {
    components: {
        desktopBoard,
        verticalBoard,
    },
    props: {
        boards: {
            type: Array,
            required: true,
        },
        stats: {
            type: Object,
            required: true,
        },
        cycle: {
            type: [Number, String],
            default: 15000,
        },
        moment: {},
        monthProgress: {},
    },
    data: () => ({
        carousel: 0,
    }),
    computed: {
        slides() {
            // return chunk(this.boards, 2);
            return chunk(this.boards, 4);
        },
        slide() {
            return this.slides[this.carousel];
        },
        cellWidth() {
            if (!Array.isArray(this.slide)) {
                return 12;
            }
            let ret = 12 / (this.slide.length);
            if (ret < 4) {
                ret = 24 / (this.slide.length);
            }
            return ret;
            // return 12;
        },
        vertical() {
            if (!Array.isArray(this.slide)) {
                return false;
            }
            return ([3].indexOf(this.slide.length) > -1);
            // return false;
        },
        doubleSize() {
            if (!Array.isArray(this.slide)) {
                return false;
            }
            return ([1].indexOf(this.slide.length) > -1);
        },
        cellHeightStyle() {
            if (!Array.isArray(this.slide)) {
                return 'height: 100%; max-height: 100%;';
            }
            if (this.slide.length == 4) {
                return 'height: 50%; max-height: 50%;';    
            }
            return 'height: 100%; max-height: 100%;';
        }
    },
    watch: {
        boards() {
            if (this.carousel >= this.slides.length) {
                this.carousel = 0;
            }
        }
    }
}
</script>

<style lang="scss">
    .sb-carousel {
        .v-image.v-responsive.v-carousel__item {
            height: 100% !important;
        }
    }
</style>