/**
 * SweetAlert 2
 */

import Swal from 'sweetalert2'
import '@sweetalert2/theme-material-ui/material-ui.scss';

/**
 * Update SweetAlert2 with specific settings
 */
const NS = Swal.mixin({
    target: '#app',
    background: '#1d1d1f',
    customClass: {
        container: 'white--text',
        title: 'title text-justify w-100 ' + 'white--text',
        content: 'body-1 text-justify ' + 'white--text',
        confirmButton: 'body-2 ' + 'secondary white--text',
        cancelButton: 'body-2 ' + 'dark white--text',
    }
})

class VSwal {
    static install (Vue) {
        Vue.prototype.$Swal = NS;
    }
}

export default VSwal;