<template>
    <v-dialog :value="!$auth.meta.mfa" max-width="400" persistent :overlay-opacity="1" overlay-color="background">
        <validation-observer slim ref="observer" v-slot="{ invalid }">
            <v-card color="panel">
                <v-toolbar color="toolbar" dense flat>
                    <v-toolbar-title>Feriority Stats Multifactor Authentication</v-toolbar-title>
                </v-toolbar>
                <v-divider color="white"></v-divider>
                <v-card-text class="pt-3">
                    <p>If you do not have the Authy app, a one-time password has been sent to your mobile phone. Otherwise, use the token from Authy.</p>
                    <validation-provider v-slot="{ errors }" name="One Time Password" rules="required" v-if="requestedCode">
                        <v-text-field type="text" v-model.trim="form.totp" :loading="loading" :disabled="loading" :error-messages="errors" label="One Time Password" required clearable cb="login" @keydown="cbIfEnter"></v-text-field>
                    </validation-provider>
                    <p>Don't have Authy yet? Click <a href="https://authy.com/download/" target="_blank">here</a> to download.</p>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn color="error" text :loading="loading" @click="$auth.logout">
                        <v-icon :class="!$vuetify.rtl ? 'mr-2' : 'ml-2'" v-text="!$vuetify.rtl ? 'mdi-chevron-left' : 'mdi-chevron-right'" v-if="$vuetify.breakpoint.smAndUp"></v-icon>
                        <span>Cancel</span>
                    </v-btn>
                    <v-btn color="secondary" text :loading="loading || !canRequest" @click="getCode" v-if="requestedCode">
                        <v-icon :class="!$vuetify.rtl ? 'mr-2' : 'ml-2'">mdi-refresh</v-icon>
                        <span>Get Code</span>
                        <template v-slot:loader>
                            <span v-if="!loading">
                                <v-progress-circular size="20" :value="progressValue" :class="!$vuetify.rtl ? 'mr-2' : 'ml-2'" v-if="$vuetify.breakpoint.smAndUp">
                                    <span style="font-size: 6px;">{{timeBeforeRequest}}</span>
                                </v-progress-circular>
                                <span class="grey--text text--lighten-1">Get Code</span>
                            </span>
                        </template>
                    </v-btn>
                    <v-btn color="primary" text :disabled="invalid" :loading="loading" @click="login" v-if="requestedCode">
                        <span>Verify</span>
                        <v-icon :class="$vuetify.rtl ? 'mr-2' : 'ml-2'" v-text="$vuetify.rtl ? 'mdi-chevron-left' : 'mdi-chevron-right'" v-if="$vuetify.breakpoint.smAndUp"></v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </validation-observer>
    </v-dialog>
</template>

<script>
export default {
  data: () => ({
    form: {
        totp: '',
    },
    requestedCode: true,
    loading: false,
    timeouts: {
        canRequest: null,
    },
    intervals: {
        canRequest: null,
    },
    timeBeforeRequest: 59,
  }),
  computed: {
      canRequest() {
          return (this.timeBeforeRequest == 0)
      },
      progressValue() {
          const remaining = 60 - this.timeBeforeRequest;
          return Math.round((remaining / 60) * 100);
      }
  },
  methods: {
      resetCanRequestTimeout() {
        this.timeBeforeRequest = 60;
      },
      async getCode() {
        this.loading = true;
        await this.$auth.getCode();
        this.loading = false;
        this.resetCanRequestTimeout();
      },
      async login() {
        const valid = await this.$refs.observer.validate();
        if (!valid) {
            console.warn(`Got Errors:`, this.$refs.observer.errors);
            return false;
        }
        this.loading = true;
        await this.$auth.submitMfa(this.form);
        this.loading = false;
      }
  },
  mounted() {
        this.resetCanRequestTimeout();
        this.$watch('$auth.authenticated', (isauth) => {
            if (isauth) {
                this.resetCanRequestTimeout();
            }
        })
        this.intervals.canRequest = setInterval(() => {
            if (this.timeBeforeRequest > 0) {
                this.timeBeforeRequest --;
            }
        }, 1000)
  },
  beforeDestroy() {
      for (let key in this.timeouts) {
          clearTimeout(this.timeouts[key]);
      }
      for (let key in this.intervals) {
          clearInterval(this.intervals[key]);
      }
  },
};
</script>
