/**
 * Class which can be extended which has triggerable events
 */

import shortid from 'shortid';

class Triggerable {
    #events = new Map();
    #vevents = new Map();
    #onces = new Map();
    constructor() {}

    on(event, cb, onlyifvis) {
        if (!this.#events.has(event)) {
            this.#events.set(event, new Map());
        }
        if (!this.#vevents.has(event)) {
            this.#vevents.set(event, new Map());
        }
        const eid = shortid.generate();
        if (onlyifvis) {
            this.#vevents.get(event).set(eid, cb);
        }
        else {
            this.#events.get(event).set(eid, cb);
        }
        return eid;
    }

    once(event, cb) {
        if (!this.#onces.has(event)) {
            this.#onces.set(event, new Map());
        }
        const eid = shortid.generate();
        this.#onces.get(event).set(eid, cb);
        return eid;
    }

    off(event, eid) {
        if (this.#events.has(event)) {
            if (eid) {
                this.#events.get(event).delete(eid);
            }
            else {
                this.#events.delete(event);
            }
        }
        if (this.#vevents.has(event)) {
            if (eid) {
                this.#vevents.get(event).delete(eid);
            }
            else {
                this.#vevents.delete(event);
            }
        }
        if (this.#onces.has(event)) {
            if (eid) {
                this.#onces.get(event).delete(eid);
            }
            else {
                this.#onces.delete(event);
            }
        }
    }

    async trigger() {
        const args = [...arguments];
        let event;
        if (args.length > 0) {
            event = args.shift();
        }
        const promises = [];
        if (this.#events.has(event)) {
            this.#events.get(event).forEach((cb) => {
                promises.push(cb.apply(null, args));
            })
        }
        if (this.#vevents.has(event) && !document.hidden ) {
            this.#vevents.get(event).forEach((cb) => {
                promises.push(cb.apply(null, args));
            })
        }
        if (this.#onces.has(event)) {
            this.#onces.get(event).forEach((cb, eid) => {
                promises.push(cb.apply(null, args));
                this.#onces.get(event).delete(eid);
            })
        }
        await Promise.all(promises);
    }
}

export default Triggerable;